import { Box, Card, Grid } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import EvgmTranslationText from '../shared/EvgmTranslationText'
import { v4 as uuid } from 'uuid'
import CodeMasterViewField from 'src/components/field/CodeMasterViewField'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'

const {
    CngGridItem
} = components

const FormBody = (props) => {
    
    const evgmTranslatedTextsObject = EvgmTranslationText()

    let refIdList = props.refIdData?.references

    return(
        <Grid container xs={12} sm={12}>
            <CngGridItem xs={12} sm={12}>
                <Card>
                    <Box className='view-card-header'>
                        <Grid container>
                            <CngGridItem xs={6} sm={6}>
                                {evgmTranslatedTextsObject.invoiceInformationTitle}
                            </CngGridItem>
                        </Grid>
                    </Box>

                    <Box>
                        {refIdList.map((row) => {
                            const rowKey = uuid() 

                            return(
                            <Grid container key={rowKey}>
                                <CngGridItem xs={4} sm={4}>
                                    <Box className='view-card-content'>
                                        <Box className='view-card-content-label'>
                                            {evgmTranslatedTextsObject.refIdType}
                                        </Box>
                                        <Box className='view-card-content-value'>
                                            <CodeMasterViewField
                                                codeType='EVGM_REF_TYPE'
                                                code={row.refType  || ''}
                                            />
                                        </Box>
                                    </Box>
                                </CngGridItem>

                                <CngGridItem xs={4} sm={4}>
                                    <LabelValueVertical
                                        label={evgmTranslatedTextsObject.refIdValue}
                                        value={row.refCode  || ''}
                                    />
                                </CngGridItem>
                            </Grid>
                            )
                        })}
                    </Box>
                </Card>
            </CngGridItem>
        </Grid>

    )
}

const initialValues = Object.freeze({})

const ViewEvgmRefIdComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ViewEvgmRefIdComponent