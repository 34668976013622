import {
    Box,
    Typography
  } from '@material-ui/core'
  
  import CheckboxGroup from './CheckboxGroup'
  import React from 'react'
  import SearchScheduleTranslationText from 'src/views/vesselschedule/searchschedule/SearchScheduleTranslationText'
  
  const CarrierFilterComponents = (props) => {
    const translatedTextsObject = SearchScheduleTranslationText()
  
    return (
      <section className={'cng-filter-pane--section-container'}>
        <header>
          <Typography className={'cng-filter-pane--section-header-label'}>
            {translatedTextsObject.carrier}
          </Typography>
        </header>
        <article>
          <Box className={`cng-filter-pane--controls-wrapper`}>
            <CheckboxGroup
              checkBoxList={props.carriers}
              onChange={props.onCarrierCheckBoxChange}
              filterName={'Carrier'}
            />
          </Box>
        </article>
      </section>
    )
}
  
export default CarrierFilterComponents