import { Box, Typography } from '@material-ui/core'

import React from 'react'
import { components } from 'cng-web-lib'

const {
  form: {
    field: { CngSwitchField }
  },
  CngGridItem
} = components

const FilterFooterComponent = (props) => {

  return (
    <section
      className={
        'cng-filter-pane--section-container cng-filter-pane--section-footer'
      }
    >
      <footer className={`cng-filter-pane--footer`}>
        <Box className={`cng-filter-pane--footer-label-box`}>
          <Typography class={'cng-filter-pane--footer-label'}>
            {props.label}
          </Typography>
        </Box>
        <Box className={`cng-filter-pane--footer-cta-box`}>
        </Box>
      </footer>
    </section>
  )
}

export default FilterFooterComponent