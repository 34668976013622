import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import CancelIcon from '@material-ui/icons/Cancel'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import Menu from '@material-ui/core/Menu'
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined'
import BookingActionMenuItem from 'src/views/freightbooking/components/BookingActionMenuItem'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'

const copyIcon = <FileCopyIcon fontSize='small' />
const cancelIcon = <CancelIcon fontSize='small' />
const deleteIcon = <DeleteIcon fontSize='small' />

const ActionMenuEvgmList = (props) => {
    const { actions, eventHandlers} = props
    const [anchorEl, setAnchorEl] = useState(null)
    const translationObject = CalistaUiComponentTranslationText()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
      }
    
    const handleClose = () => {
    setAnchorEl(null)
    }

    return(
        <Box>
            <IconButton
                aria-label='more'
                aria-controls='long-menu'
                aria-haspopup='true'
                onClick={handleClick}
                className={'moreMenuBtn'}
            >
                <MoreHorizOutlinedIcon />
            </IconButton>

            {actions.length > 0 && (
                <Menu
                    id='simple-menu'
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {actions.map((action, idx) => {
                        switch (action) {
                            case SIUtils.Action.copy:
                                return (
                                <BookingActionMenuItem
                                    icon={copyIcon}
                                    label={translationObject.copy}
                                    action={eventHandlers.copyEvgm}
                                />
                            )

                            default:
                                return <div></div>
                        }
                    })}


                </Menu>

            )}
        </Box>
    )
}

export default ActionMenuEvgmList