import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Grid,
    InputAdornment,
    IconButton
} from '@material-ui/core'
import { v4 as uuid } from 'uuid'
import React, { useEffect, useState } from 'react'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import { components, useServices  } from 'cng-web-lib'
import EvgmTranslationText from '../shared/EvgmTranslationText'
import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import ListCompanyContactDialog from 'src/views/company-preference/addressbook/components/addressbook/ListCompanyContactDialog'
import ListPartyTemplateDialog from 'src/views/company-preference/addressbook/components/partytemplate/ListPartyTemplateDialog'
import AddContactSIDialog from 'src/views/company-preference/addressbook/components/addressbook/AddContactSIDialog'


const {
    form: {
        adapter: {
        useFormAdapter: { useField, useFormikContext }
        },
        field: {
        CngCodeMasterAutocompleteField,
        CngTextField,
        CngSelectField
        }
    },
    CngGridItem,
    table: { useDefaultNotification }
} = components

const initialValues = Object.freeze({
    shipperPartyIdCode: '',
    shipperPartyName: '',
    shipperPartyIdType: '',
    shipperAgencyCode:'',
    shipperContactType: '',
    shipperContactName: '',
    shipperContactAddress:'',
    shipperAddressCityName:'',
    shipperAddressPostalCode:'',
    shipperAddressCountryName:'',
    shipperAddressCountryCode:'',
    shipperPhoneNumber:'',
    shipperEmailAddress:''
})

const FormBody = (props) => {

    const { success: showSuccessNotification } =
    useDefaultNotification(props.showNotification)
    const evgmTranslatedTextsObject = EvgmTranslationText()
    const [additionalInfoExpanded, setAdditionalInfoExpanded] = useState(false)
    const [addressBookDialogOpen, setAddressBookDialogOpen] = useState(false)
    const [partyTemplateDialogOpen, setPartyTemplateDialogOpen] = useState(false)
    const [addContactDialogOpen, setAddContactDialogOpen] = useState(false)
    const [refreshContactList, setRefreshContactList] = useState(false)
    const [contactType, setContactType] = useState('')
    const { setFieldValue } = useFormikContext()
    const [carrierKey, setCarrierKey] = useState()
    const { fetchRecords } = useServices()
    
    function closeAddContactDialog() {
        setAddContactDialogOpen(false)
    }

    const handleAdditionalInfoExpanded = () => {
        setAdditionalInfoExpanded(!additionalInfoExpanded)
    }

    const doRefresh = () => {
        setRefreshContactList(!refreshContactList)
    }

    const getAddressbook = (contactType) => {
        setAddressBookDialogOpen(true)
        setContactType(contactType)
    }

    function closeDialog() {
        setAddressBookDialogOpen(false)
        setPartyTemplateDialogOpen(false)
    }

    function handleSelectContact(data) {
        console.log('handleSelectContact', data)
        
        setFieldValue('shipperContactName', data.shpName)
        setFieldValue('shipperContactAddress', data.shpAddress)
        setFieldValue('shipperEmailAddress', data.shpEmail)
        setFieldValue('shipperAddressCityName', data.shpCityName)
        setFieldValue('shipperAddressPostalCode', data.shpPostalCode)
        setFieldValue('shipperAddressCountryCode', data.shpCountryCode)
        setFieldValue('shipperAddressCountryName', data.shpCountryName)
        setFieldValue('shipperPhoneNumber', data.shpContactNo)

        showSuccessNotification('Shipper details populated')

        closeDialog()
    }

    const addNewContact = (contactType) => {
        console.log('addNewContact', contactType)
        setAddContactDialogOpen(true)
        setContactType(contactType)
    }

    function handleSelectTemplate(data){
        console.log('handleSelectTemplate', data)

        setFieldValue(
            'shipperContactName',
            data.selectedShipperName != null ? data.selectedShipperName : ''
        )
        setFieldValue(
            'shipperContactAddress',
            data.selectedShipperAddress != null ? data.selectedShipperAddress : ''
        )
        setFieldValue(
            'shipperEmailAddress',
            data.selectedShipperEmail != null ? data.selectedShipperEmail : ''
        )
        setFieldValue(
            'shipperPhoneNumber',
            data.selectedShipperContactNo != null ? data.selectedShipperContactNo : ''
        )

        setFieldValue(
            'shipperAddressCityName',
            data.selectedCarrierCityName != null ? data.selectedCarrierCityName : ''
        )

        setFieldValue(
            'shipperAddressPostalCode',
            data.selectedShipperPostalCode != null ? data.selectedShipperPostalCode : ''
        )

        setFieldValue(
             'shipperAddressCountryCode',
             data.selectedShipperCountryCode != null ? data.selectedShipperCountryCode : ''
        )

        setFieldValue(
             'shipperAddressCountryName',
             data.selectedShipperCountryName != null ? data.selectedShipperCountryName : ''
        )

        showSuccessNotification('Party Template details populated.')
        closeDialog()
    }

    function fetchUserRoleFromAPI() {
        fetchRecords.execute(
          `${process.env.REACT_APP_USER_ORIGIN_URL}/tpr-auth/user-detail/by-login/get`,
          undefined,
          (data) => {
            console.log('fetchUserRoleFromAPI data: ', data)
            if (data.length > 0) {
                setFieldValue('shipperPartyName', data[0]?.party?.name)
            }
          },
          (error) => {
            console.log(error)
          }
        )
    }

    useEffect(() => {

        if(props.isEdit){
            setCarrierKey(uuid())

            console.log('check shipper data brought', props.shipperData)

            const shipperData = props.shipperData

            // Initialize variables
            //let shipperPartyIdCode = null
            let shipperPartyName = null
            let shipperPartyIdType = null
            let shipperAgencyCode = null
            let shipperContactType = null
            let shipperContactName = null
            let shipperContactAddress = null
            let shipperPhoneNumber = null
            let shipperEmailAddress = null
            let shipperAddressCityName = null
            let shipperAddressPostalCode = null
            let shipperAddressCountryName = null
            let shipperAddressCountryCode = null

            // Find the object with role "CA"
            const caParty = shipperData.find((party) => party.role == "FW");

            if(props.isCopy){
                if (caParty) {
                    //shipperPartyIdCode = caParty.partyId || ''
                    shipperPartyName = caParty.name || ''
                    shipperPartyIdType = caParty.partyIdType || caParty.partyIdCode || ''
                    shipperAgencyCode = caParty.agencyCode || ''
                    shipperContactType = caParty.partyContacts?.[0]?.contactType || ''
                    shipperContactName = caParty.partyContacts?.[0]?.contactName || ''
                    shipperContactAddress = caParty.address?.streetAddress || caParty.streetAddress || ''
                    shipperAddressPostalCode = caParty.address?.postalCode || caParty.postalCode || ''
                    shipperAddressCityName = caParty.address?.cityName || caParty.cityName || ''
                    shipperAddressCountryName = caParty.address?.countryName || caParty.countryName || ''
                    shipperAddressCountryCode = caParty.address?.countryCode || caParty.countryCode || ''
    
                    //extract carrierPhoneNumber
                    const teCommunication = caParty.partyContacts?.[0]?.contactCommunication.find(
                        (communication) => communication.commType == "TE"
                    )
                    
                    if (teCommunication) {
                        shipperPhoneNumber = teCommunication.commNo
                    }
    
                    //extract carrierEmailAddress
                    const bnCommunication = caParty.partyContacts?.[0]?.contactCommunication.find(
                        (communication) => communication.commType == "EM"
                    )
                    
                    if (teCommunication) {
                        shipperEmailAddress = bnCommunication.commNo
                    }
                }
            }else{
                if (caParty) {
                    //shipperPartyIdCode = caParty.partyId || ''
                    shipperPartyName = caParty.partyName || ''
                    shipperPartyIdType = caParty.partyIdType || caParty.partyIdCode || ''
                    shipperAgencyCode = caParty.agencyCode || ''
                    shipperContactType = caParty.partyContacts?.[0]?.contactType || ''
                    shipperContactName = caParty.partyContacts?.[0]?.contactName || ''
                    shipperContactAddress = caParty.address?.streetAddress || caParty.streetAddress || ''
                    shipperAddressPostalCode = caParty.address?.postalCode || caParty.postalCode || ''
                    shipperAddressCityName = caParty.address?.cityName || caParty.cityName || ''
                    shipperAddressCountryName = caParty.address?.countryName || caParty.countryName || ''
                    shipperAddressCountryCode = caParty.address?.countryCode || caParty.countryCode || ''
    
                    //extract carrierPhoneNumber
                    const teCommunication = caParty.partyContacts?.[0]?.contactCommunication.find(
                        (communication) => communication.commType == "TE"
                    )
                    
                    if (teCommunication) {
                        shipperPhoneNumber = teCommunication.commNo
                    }
    
                    //extract carrierEmailAddress
                    const bnCommunication = caParty.partyContacts?.[0]?.contactCommunication.find(
                        (communication) => communication.commType == "EM"
                    )
                    
                    if (teCommunication) {
                        shipperEmailAddress = bnCommunication.commNo
                    }
                }
            }
            

            // Now you have the values assigned to the desired variables
            //setFieldValue('shipperPartyIdCode', shipperPartyIdCode)
            setFieldValue('shipperPartyName', shipperPartyName)
            setFieldValue('shipperPartyIdType', shipperPartyIdType)
            setFieldValue('shipperAgencyCode', shipperAgencyCode)
            setFieldValue('shipperContactType', shipperContactType)
            setFieldValue('shipperContactName', shipperContactName)
            setFieldValue('shipperContactAddress', shipperContactAddress)
            setFieldValue('shipperAddressPostalCode', shipperAddressPostalCode)
            setFieldValue('shipperAddressCityName', shipperAddressCityName)
            setFieldValue('shipperAddressCountryName', shipperAddressCountryName)
            setFieldValue('shipperAddressCountryCode', shipperAddressCountryCode)
            setFieldValue('shipperPhoneNumber', shipperPhoneNumber)
            setFieldValue('shipperEmailAddress', shipperEmailAddress)
        }else{
            fetchUserRoleFromAPI()
        }

    }, [])

    
    return(
        <Card>
            <Accordion expanded={additionalInfoExpanded}>
                <AccordionSummary>
                    <Grid container>
                        <CngGridItem xs={6} sm={6}>
                            <AccordionHeaderComponent 
                                title = {evgmTranslatedTextsObject.shipperInfoTitle}
                            />
                        </CngGridItem>

                        <Grid container spacing={2} alignItems='center'>

                            <CngGridItem xs={4} sm={4}>
                                <CngTextField
                                    type= 'text'
                                    name = 'shipperPartyName'
                                    label = {evgmTranslatedTextsObject.shipperPartyId}
                                    inputProps = {{ maxLength: 512 }}
                                    required
                                >
                                </CngTextField>
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                <CngCodeMasterAutocompleteField
                                    name='shipperPartyIdType'
                                    label={evgmTranslatedTextsObject.partyIdType}
                                    codeType='EVGM_PARTY_ID_TYPE'
                                    key={carrierKey}
                                    required
                                />
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                <CngCodeMasterAutocompleteField
                                    name='shipperAgencyCode'
                                    label={evgmTranslatedTextsObject.agencyCode}
                                    codeType='EVGM_AGENCY_CODE'
                                    key={carrierKey}
                                    required
                                />
                            </CngGridItem>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <CngGridItem xs={4} sm={4}>
                            <CngCodeMasterAutocompleteField
                                name='shipperContactType'
                                label={evgmTranslatedTextsObject.shipperContactType}
                                codeType='EVGM_CONTACT_TYPE'
                                key={carrierKey}
                                //required
                            />
                        </CngGridItem>
                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type= 'text'
                                name = 'shipperContactName'
                                label = {evgmTranslatedTextsObject.contactName}
                                inputProps = {{ maxLength: 512 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                onClick={(e) => getAddressbook('SHIPPER')}
                                            >
                                            {' '}
                                                <LibraryBooksIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </CngGridItem>
                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type= 'text'
                                name = 'shipperContactAddress'
                                label = {evgmTranslatedTextsObject.contactAddress}
                                inputProps = {{ maxLength: 512 }}
                            />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}/>

                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type= 'text'
                                name = 'shipperPhoneNumber'
                                label = {evgmTranslatedTextsObject.phoneNumber}
                                inputProps = {{ maxLength: 256 }}
                            />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}/>
                        <CngGridItem xs={4} sm={4}/>

                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type= 'text'
                                name = 'shipperEmailAddress'
                                label = {evgmTranslatedTextsObject.emailAddress}
                                inputProps = {{ maxLength: 512 }}
                            />
                        </CngGridItem>
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <AccordionFooterControlComponent
                handleAccordionExpand={handleAdditionalInfoExpanded}
                expanded={additionalInfoExpanded}
                footerText={evgmTranslatedTextsObject.shipperAdditional}
            />

            <ListCompanyContactDialog
                isDialogOpen={addressBookDialogOpen}
                closeDialog={closeDialog}
                contactType={contactType}
                handleDialogSubmit={handleSelectContact}
                handleAddContact={addNewContact}
                showNotification={props.showNotification}
                refresh={refreshContactList}
            />

            <ListPartyTemplateDialog
                styles={{ width: '80%', height: '90%' }}
                isDialogOpen={partyTemplateDialogOpen}
                closeDialog={closeDialog}
                handleDialogSubmit={handleSelectTemplate}
                showNotification={props.showNotification}
            />

            <AddContactSIDialog
                isDialogOpen={addContactDialogOpen}
                closeDialog={closeAddContactDialog}
                contactType={contactType}
                showNotification={props.showNotification}
                doRefresh={doRefresh}
            />
        </Card>
    )
}

const EvgmShipperComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default EvgmShipperComponent