import { Box, Button, Grid } from '@material-ui/core'
import CalistaUiComponentTranslationText from '../../common/CalistaUiComponentTranslationText'
import React from 'react'
import EvgmTranslationText from '../shared/EvgmTranslationText'

function DraftEvgmButtonComponent(props){
    const translatedTextsObject = CalistaUiComponentTranslationText()
    const evgmTranslatedTextsObject = EvgmTranslationText()

    return(

        <Box pl={5} pr={5} pt={4} pb={4}>
            <Grid container xs={12} sm={12} justify='flex-start'>
                <Grid xs={6} sm={6}>
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={() => {
                        props.onDiscard()
                        }}
                        classes={{ root: 'ng-button-filled-secondary' }}
                    >
                        {translatedTextsObject.discard}
                    </Button>
                </Grid>
                <Grid container xs={6} sm={6} justify='flex-end' spacing={2}>
                    <Box pr={2}>
                        <Button
                        variant='contained'
                        className='button-blue originalText'
                        onClick={() => {
                            props.onEdit()
                        }}
                        >
                        {evgmTranslatedTextsObject.backToEdit}
                        </Button>
                    </Box>
                    <Box>
                        <Button
                            variant='contained'
                            className='button-light-green originalText'
                            onClick={() => {
                                props.onSubmitEvgm()
                            }}
                        >
                            {evgmTranslatedTextsObject.submitEvgm}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    )
}

export default DraftEvgmButtonComponent