import {
    Box,
    Grid,
    Typography,
} from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import EvgmTranslationText from '../shared/EvgmTranslationText'
import React from 'react'
import {
    components
} from 'cng-web-lib'

const {
    button: { CngPrimaryButton, CngSecondaryButton }
} = components

const EvgmListNoResult = props => {
    const translatedTextsObject = EvgmTranslationText()

    return (
        <div className={'vs-noresult'}>
            <img
                src={
                    process.env.PUBLIC_URL + '/static/images/freightBooking/noresult.png'
                }
                alt='No Result'
            ></img>
            <Typography variant='h3' style={{ fontWeight: 'bold', paddingTop: '10px'}}>
                {translatedTextsObject.noItemHereYet}
            </Typography>
            {
                <Typography variant='subtitle1' style={{ paddingTop: '10px', color:'#808080'}}>
                    {translatedTextsObject.noItemDescCarrier}
                </Typography>
            }
            {
                <Box pt={2}>
                    <Grid container>
                        <CngSecondaryButton onClick={props.refreshAction}>
                            <RefreshIcon />
                            <Box pl={1}>
                                {translatedTextsObject.refresh}
                            </Box>
                        </CngSecondaryButton>
                    </Grid>
                </Box>
            }
        </div>
    )
}

export default EvgmListNoResult;