import { Box, Card, Grid } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import EvgmTranslationText from '../shared/EvgmTranslationText'
import { v4 as uuid } from 'uuid'
import CodeMasterViewField from 'src/components/field/CodeMasterViewField'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'

const {
    form: {
      field: { CngSwitchField, CngTextField, CngCodeMasterAutocompleteField }
    },
    CngGridItem
} = components

const FormBody = (props) => {
    
    const evgmTranslatedTextsObject = EvgmTranslationText()
    console.log('Check container doc info data', props.containerDocInfoData)

    return(
        <Grid container xs={12} sm={12}>
             <CngGridItem xs={12} sm={12}>
                <Card>
                    <Box className='view-card-header'>
                        <Grid container xs={12} sm={12}>
                            <CngGridItem xs={6} sm={6}>
                                {evgmTranslatedTextsObject.ctnDocInfoTitle}
                            </CngGridItem>
                        </Grid>
                    </Box>

                    <Box>
                        {props.containerDocInfoData ? (
                            props.containerDocInfoData.map((row) => {
                                const rowKey = uuid() 

                                return(
                                    <Grid container key={rowKey} xs={12} sm={12}>

                                        <CngGridItem xs={4} sm={4}>
                                            <Box className='view-card-content'>
                                                <Box className='view-card-content-label'>
                                                    {evgmTranslatedTextsObject.ctnDocType}
                                                </Box>
                                                <Box className='view-card-content-value'>
                                                    <CodeMasterViewField
                                                        codeType='EVGM_DOC_TYPE'
                                                        code={row.infoType}
                                                    />
                                                </Box>
                                            </Box>
                                        </CngGridItem>

                                        <CngGridItem xs={4} sm={4}>
                                            <LabelValueVertical
                                                label={evgmTranslatedTextsObject.ctnDocPartyName}
                                                value={row.name}
                                            />
                                        </CngGridItem>

                                        <CngGridItem xs={4} sm={4}>
                                            <LabelValueVertical
                                                label={evgmTranslatedTextsObject.ctnDocPartyAddress}
                                                value={row.address?.streetAddress}
                                            />
                                        </CngGridItem>

                                        <CngGridItem xs={4} sm={4}>
                                            <Box className='view-card-content'>
                                                <Box className='view-card-content-label'>
                                                    {evgmTranslatedTextsObject.ctnDocContactType}
                                                </Box>
                                                <Box className='view-card-content-value'>
                                                    <CodeMasterViewField
                                                        codeType='EVGM_DOC_CONTACT_TYPE'
                                                        code={row.contactInformation[0]?.contactType}
                                                    />
                                                </Box>
                                            </Box>
                                        </CngGridItem>

                                        <CngGridItem xs={4} sm={4}>
                                            <LabelValueVertical
                                                label={evgmTranslatedTextsObject.ctnDocContactName}
                                                value={row.contactInformation[0]?.contactName}
                                            />
                                        </CngGridItem>

                                        <CngGridItem xs={4} sm={4}>

                                        </CngGridItem>

                                        <CngGridItem xs={4} sm={4}>
                                            <Box className='view-card-content'>
                                                <Box className='view-card-content-label'>
                                                    {evgmTranslatedTextsObject.ctnDocCommType}
                                                </Box>
                                                <Box className='view-card-content-value'>
                                                    <CodeMasterViewField
                                                        codeType='EVGM_COMM_TYPE'
                                                        code={row.contactInformation[0]?.communicationInfo[0]?.commType}
                                                    />
                                                </Box>
                                            </Box>
                                        </CngGridItem>

                                        <CngGridItem xs={4} sm={4}>
                                            <LabelValueVertical
                                                label={evgmTranslatedTextsObject.ctnDocCommValue}
                                                value={row.contactInformation[0]?.communicationInfo[0]?.commNo}
                                            />
                                        </CngGridItem>

                                    </Grid>
                                )
                            })
                            ):(
                                <></>
                            )
                        }
                    </Box>
                </Card>
            </CngGridItem>
        </Grid>
    )
}

const initialValues = Object.freeze({})

const ViewEvgmContainerDocInfoComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ViewEvgmContainerDocInfoComponent