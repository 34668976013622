import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'
import EssDocsSSOPage from './sso/EssDocsSSOPage.js'
import useBreadcrumbNameMap from 'src/useBreadcrumbNameMap'

function WrappedEssDocsSSOPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      renderPage={(showSnackbar) => (
        <EssDocsSSOPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export {
    WrappedEssDocsSSOPage as EssDocsSSOPage
}
