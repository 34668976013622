import { Box, Grid } from '@material-ui/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { components, useServices } from 'cng-web-lib'

import EvgmDetailComponent from './EvgmDetailComponent'
import CircularProgress from '@material-ui/core/CircularProgress'
import EvgmApiUrls from 'src/apiUrls/EvgmApiUrls'
import ResetFilterComponent from './ResetFilterComponent'

const { CngGridItem } = components

const initialValues = Object.freeze({})

const FormBody = (props) => {
    const observer = useRef()

    const [hasMore, setHasMore] = useState(false)
    const [loading, setLoading] = useState(false)

    const [displayedEvgm, setDisplayedEvgm] = useState([])

    const loadAmount = 10

    const { fetchPageableRecords } = useServices()
  
    const [pageNumber, setPageNumber] = useState(0)
  
    let searchCriteria = props.searchCriteria

    useEffect(() => {
        console.log('page number changes ' + pageNumber)
        console.log('search criteria: ' + searchCriteria)

        if (pageNumber > 0) {
            setLoading(true)

            ProgressiveLoadingEvgm()
        }
    }, [pageNumber])

    function ProgressiveLoadingEvgm() {
        console.log('ProgressiveLoadingEvgm')
        fetchPageableRecords.execute(
            EvgmApiUrls.EVGM_LIST,
            {
                filters: [],
                sorts: [],
                page: pageNumber,
                pageSize: loadAmount,
                customData: searchCriteria
            },
            (data) => {
                console.log('Evgm is back!')
                console.log(data.content)

                if (data.content.length < loadAmount) {
                    setHasMore(false)
                } else {
                    setHasMore(true)
                }

                let temp3 = [...displayedEvgm]
                console.log(temp3)
                let temp4 = temp3.concat(data.content)
                console.log(temp4)

                setDisplayedEvgm(temp4)
                setLoading(false)
            },
            (error) => {
                props.setPageError(true)
            }
        )
    }

    useEffect(() => {
        setLoading(false)
        let temp = [...props.evgmList]
        if (temp.length < loadAmount) {
            setHasMore(false)
        } else {
            setHasMore(true)
        }
        console.log(hasMore)
        setPageNumber(0)

        setDisplayedEvgm(temp)
        console.log(displayedEvgm)
    }, [props.evgmList])

    const lastEvgmElementRef = useCallback(
        (node) => {
            if (loading) return
            if (observer.current) observer.current.disconnect()
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                  setPageNumber((prevPageNumber) => prevPageNumber + 1)
                  console.log(hasMore)
                }
              })
              if (node) observer.current.observe(node)
        },
        [loading, hasMore]
    )

    return (
        <>
            {displayedEvgm.map((m, idx) => (
                <div key={m} ref={lastEvgmElementRef}>
                    <EvgmDetailComponent.FormBody evgm={m} {...props} />
                </div>
            ))}
    
          <div style={{ display: loading ? 'inline' : 'none' }}>
            <Grid container justify='center'>
              <CngGridItem>
                <CircularProgress />
              </CngGridItem>
            </Grid>
          </div>
    
          <Box mt={5} style={{ display: loading ? 'none' : 'inline' }}>
            <ResetFilterComponent
              leftCount={props.leftCount}
              handleClearAllButtonClick={props.handleClearAllButtonClick}
            />
          </Box>
        </>
    )
}

const EvgmListComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default EvgmListComponent