import { Box, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'

import EvgmTranslationText from '../shared/EvgmTranslationText'
import { components } from 'cng-web-lib'

const {
    form: {
      adapter: {
        useFormAdapter: { useField, useFormikContext }
      },
      field: { CngRadioGroupField }
    },
    CngGridItem
} = components

const DateRangeFilterComponent = (props) => {
    const [, , { setValue: setDateRange }] = useField('dateRange')
    const translatedTextsObject = EvgmTranslationText()
    const dateRangeOptions = [
        { label: translatedTextsObject.evgmFilterAll, value: 'all' },
        { label: translatedTextsObject.evgmFilterDate15, value: '15' },
        { label: translatedTextsObject.evgmFilterDate30, value: '30' },
        { label: translatedTextsObject.evgmFilterDate60, value: '60' }
    ]
    
    useEffect(() => {
        console.log(props.dateRange)
        setDateRange(props.dateRange)
    }, [])

    return (
        <section className={'cng-filter-pane--section-container'}>
            <header>
                <Typography
                    variant='subtitle1'
                    className={'cng-filter-pane--section-header-label'}
                >
                    {translatedTextsObject.lastModifiedDate}
                </Typography>
            </header>
            <article>
                <Box className={`cng-filter-pane--controls-wrapper`}>
                    <CngRadioGroupField
                        name='dateRange'
                        options={dateRangeOptions}
                        direction='column'
                        onChange={(e) => {
                            props.dateRangeChange(e)
                            //submitForm()
                        }}
                    />
                </Box>
            </article>
        </section>
    )
}

export default DateRangeFilterComponent