import {
    Box,
    Card,
    Grid
} from '@material-ui/core'
import { v4 as uuid } from 'uuid'
import { components } from 'cng-web-lib'
import React, { useEffect, useRef, useState } from 'react'
import EvgmTranslationText from '../shared/EvgmTranslationText'
import EvgmDocumentInfoComponent from './EvgmDocumentInfoComponent'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'

const {
    form: {
        adapter: {
        useFormAdapter: { useField, useFormikContext }
        },
        field: {
        CngCodeMasterAutocompleteField,
        CngTextField,
        CngSelectField,
        CngDateTimeField
        }
    },
    CngGridItem,
    table: { useDefaultNotification }
} = components

const initialValues = Object.freeze({
    evgmContainer:{
        containerDocument: {
            docCode:'',
            docId:'',
            issueDate:'',
            docInformation:[
                {
                    infoType:'',
                    name:'',
                    address:{
                        streetAddress:'',
                        // Fill in the city, postal code, country as needed
                        cityName: "",
                        postalCode: "",
                        countryCode: "",
                        countryName: ""
                    },
                    contactInformation:[
                        {
                        contactType: '',
                        contactName: '',
                        communicationInfo:[{
                            commType: '',
                            commNo: ''
                        }],
                    }],
                },
            ],
        }
    }
})

const FormBody = (props) => {

    const [contKey, setContKey] = useState()

    const docInfoKeyRef = useRef(uuid())
    const [docInfoList, setDocInfoList] = useState([])

    const { setFieldValue } = useFormikContext()
    const evgmTranslatedTextsObject = EvgmTranslationText()

    const docInfoItem = {
        infoType:'',
        name:'',
        address:{
            streetAddress:'',
            // Fill in the city, postal code, country as needed
            cityName: "",
            postalCode: "",
            countryCode: "",
            countryName: ""
        },
        contactInformation:[
            {
            contactType: '',
            contactName: '',
            communicationInfo:[{
                commType: '',
                commNo: ''
            }],
        }],
    }
    
    const removeContainerDocRowClick = (id) => (e) => {

        const list = [...docInfoList]
        list.splice(id, 1)

        docInfoKeyRef.current = uuid()
        setDocInfoList(list)
        setFieldValue('evgmContainer.containerDocument.docInformation', list)

        e.stopPropagation()
    }
    const copyContainerDocRowClick = (index) => () => {
        const list = [...docInfoList]
        const copyDocInfo = list.slice(index, index + 1)[0]

        const copyDocInfoItem = { ...copyDocInfo }

        setDocInfoList([...docInfoList, copyDocInfoItem])
        setFieldValue('evgmContainer.containerDocument.docInformation', [...docInfoList, copyDocInfoItem])
    }
    const handleNameInputChange = (e, index) => {
        const { name, value } = e.target
        const list = [...docInfoList]
        console.log('check list', list)
        list[index][name.split('.')[3]] = value

        console.log(name, " is updating")
        console.log("Value is: ", value)
    
        setDocInfoList(list)
        setFieldValue('evgmContainer.containerDocument.docInformation', list)
    }

    const handleAddressInputChange = (e, index) => {
        const { name, value } = e.target
        const list = [...docInfoList]
        console.log('check list', list)
        list[index][name.split('.')[4]] = value

        console.log(name, " is updating")
        console.log("Value is: ", value)
    
        setDocInfoList(list)
        setFieldValue('evgmContainer.containerDocument.docInformation', list)
    }

    const handleCommInputChange = (e, index) => {
        const { name, value } = e.target
        const list = [...docInfoList]
        list[index][name.split('.')[5]] = value

        console.log(name, " is updating")
        console.log("Value is: ", value)
    
        setDocInfoList(list)
        setFieldValue('evgmContainer.containerDocument.docInformation', list)
    }


    useEffect(() =>{
        console.log('props.isEdit', props.isEdit)

        const docInfoListTemp = {...docInfoItem}

        if(props.isEdit){
            setContKey(uuid())
            const docData = props.docData

            setFieldValue('evgmContainer.containerDocument.docCode', docData?.docCode || '')
            setFieldValue('evgmContainer.containerDocument.docId', docData?.docId || '')
            setFieldValue('evgmContainer.containerDocument.issueDate', docData?.issueDate || '')

            console.log('check doc data brought', docData?.docInformation?.[0])
            //createDocInfoList(props.docData?.docInformation[0])
            setDocInfoList([props.docData?.docInformation[0]])
        }else{
            //for create new eVGM
            createDocInfoList(docInfoListTemp)
        }
        
    }, [])

    const createDocInfoList = (docInfo) => {
        setDocInfoList([docInfo])
        setFieldValue('evgmContainer.containerDocument.docInformation', [docInfo])
    }

    return(
        <Box>
            <Card>
                <Box px={2} my={1.5}>
                    <Grid container justify='flex-end'>
                        <AccordionHeaderComponent 
                            title = {evgmTranslatedTextsObject.ctnDocTitle}
                        />
                    </Grid>

                    <Grid container spacing={2} alignItems='center'>

                        <CngGridItem xs={4} sm={4}>
                            <CngCodeMasterAutocompleteField
                                name='evgmContainer.containerDocument.docCode'
                                label={evgmTranslatedTextsObject.ctnDocCode}
                                codeType='EVGM_DOC_CODE'
                                key={contKey}
                                isRequired
                            />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type= 'text'
                                name = 'evgmContainer.containerDocument.docId'
                                label = {evgmTranslatedTextsObject.ctnDocId}
                                inputProps = {{ maxLength: 70 }}
                                required
                            />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}>
                            <CngDateTimeField
                                name='evgmContainer.containerDocument.issueDate'
                                label={evgmTranslatedTextsObject.ctnDocDate}
                                required
                            />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}/>

                        <EvgmDocumentInfoComponent
                            list = {props.isEdit ? [props.docData?.docInformation[0]] : docInfoList}
                            removeRowClick={removeContainerDocRowClick}
                            copyRowClick={copyContainerDocRowClick}
                            handleNameInputChange = {handleNameInputChange}
                            handleAddressInputChange = {handleAddressInputChange}
                            handleCommInputChange = {handleCommInputChange}
                            isEdit = {props.isEdit ? true : false}
                        />
                    </Grid>
                </Box>
            </Card>
        </Box>
    )
}

const EvgmContainerDocumentComponent = Object.freeze({
    FormBody: FormBody,
    //initialValues: initialValues
})

export default EvgmContainerDocumentComponent