import { Box, Card, Grid } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import EvgmTranslationText from '../shared/EvgmTranslationText'
import CodeMasterViewField from 'src/components/field/CodeMasterViewField'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'
import ViewEvgmContainerDocInfoComponent from './ViewEvgmContainerDocInfoComponent'

const {
    form: {
      field: { CngSwitchField, CngTextField, CngCodeMasterAutocompleteField }
    },
    CngGridItem
} = components

const FormBody = (props) => {
    
    const evgmTranslatedTextsObject = EvgmTranslationText()
    console.log('Check container doc data', props.containerDocData)

    return(
        <Grid container xs={12} sm={12}>
             <CngGridItem xs={12} sm={12}>
                <Card>
                    <Box className='view-card-header'>
                        <Grid container xs={12} sm={12}>
                            <CngGridItem xs={6} sm={6}>
                                {evgmTranslatedTextsObject.ctnDocTitle}
                            </CngGridItem>
                        </Grid>
                    </Box>

                    <Box>
                        <Grid container xs={12} sm={12}>

                            <CngGridItem xs={4} sm={4}>
                                <Box className='view-card-content'>
                                    <Box className='view-card-content-label'>
                                        {evgmTranslatedTextsObject.ctnDocCode}
                                    </Box>
                                    <Box className='view-card-content-value'>
                                        <CodeMasterViewField
                                            codeType='EVGM_DOC_CODE'
                                            code={props.containerDocData?.docCode}
                                        />
                                    </Box>
                                </Box>
                            </CngGridItem>

                            <CngGridItem xs={4} sm={4}>
                                <LabelValueVertical
                                    label={evgmTranslatedTextsObject.ctnDocId}
                                    value={props.containerDocData?.docId}
                                />
                            </CngGridItem>

                            <CngGridItem xs={4} sm={4}>
                                <LabelValueVertical
                                    label={evgmTranslatedTextsObject.ctnDocDate}
                                    value={props.containerDocData?.issueDate}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={12}>
                                <ViewEvgmContainerDocInfoComponent.FormBody
                                    containerDocInfoData = {props.containerDocData?.docInformation}
                                />
                            </CngGridItem>
                            
                        </Grid>
                    </Box>
                </Card>
            </CngGridItem>
        </Grid>
    )
}

const initialValues = Object.freeze({})

const ViewEvgmContainerDocumentComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ViewEvgmContainerDocumentComponent