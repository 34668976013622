import {
    Box, 
    IconButton,
    Card,
    Grid
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import { components } from 'cng-web-lib'
import EvgmTranslationText from '../shared/EvgmTranslationText'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import FooterAddComponent from '../../common/ui/FooterAddComponent'


const {
    form: {
        adapter: {
        useFormAdapter: { useField, useFormikContext }
        },
        field: {
        CngCodeMasterAutocompleteField,
        CngTextField,
        CngSelectField
        }
    },
    CngGridItem,
    table: { useDefaultNotification }
} = components

const initialValues = Object.freeze({
    references: [
        {
            refType: '',
            refCode: ''
        }
    ]
})

const FormBody = (props) => {

    const evgmTranslatedTextsObject = EvgmTranslationText()
    const [refIdList, setRefIdList] = useState([])
    const { setFieldValue } = useFormikContext()


    const refItem = {
        refType: '',
        refCode: ''
    }

    const handleInputChange = (e,index) => {
        const { name, value } = e.target
        const list = [...refIdList]
        list[index][name.split('.')[1]] = value

        setRefIdList(list)
        setFieldValue("references", list)
    }
    
    const handleDropdownChange = (val, evt, index, name) => {
        if (evt) {
          const { value } = evt
          const list = [...refIdList]
          list[index][name] = value
          setRefIdList(list)
          setFieldValue("references", list, true)
        }
    }

    const handleRemoveReference = (index) => {
        const list = [...refIdList]
        list.splice(index, 1)

        setRefIdList(list)
        setFieldValue("references", list, true)
    }

    const handleAddReference = () => {
        const addReference = { ...refItem }
  
        setRefIdList([...refIdList, addReference])
        setFieldValue("references", [...refIdList, addReference], true)
    }

    const handleCopyReference = (index) => {
        const list = [...refIdList]
        const copyRef = list.slice(index, index + 1)[0]

        const copyRefItem = { ...copyRef }

        setRefIdList([...refIdList, copyRefItem])
        setFieldValue("references", [...refIdList, copyRefItem])
    }

    useEffect(() => {
        const ref = {...refItem}

        if(props.isEdit){
            setRefIdList(props.refData)
            setFieldValue("references", props.refData)
        }else{
            setRefIdList([ref])
            setFieldValue("references",[ref])
        }
        
    }, [props.isEdit])

    
    return(
        <Card>
            <Box px={2} my={1.5}>
                <Grid container>
                    <AccordionHeaderComponent
                        title={evgmTranslatedTextsObject.invoiceInformationTitle}
                    />
                </Grid>

                <Grid container spacing={2}>
                    {refIdList.map((x,i) => {
                        return(
                            <CngGridItem xs={12} sm={12} key={x.id}>
                                <Card className='inner-card-group'>
                                    <Grid container xs={12} sm={12} spacing={2}>
                                        <CngGridItem xs={4} sm={4}>
                                            <CngCodeMasterAutocompleteField
                                                name={`references[${i}].refType`}
                                                label={evgmTranslatedTextsObject.refIdType}
                                                codeType='EVGM_REF_TYPE'
                                                onChange={(val, evt) =>
                                                    handleDropdownChange(val, evt, i, 'refType')
                                                }
                                            />
                                        </CngGridItem>

                                        <CngGridItem xs={4} sm={4}>
                                            <CngTextField
                                                type= 'text'
                                                name = {`references[${i}].refCode`}
                                                label = {evgmTranslatedTextsObject.refIdValue}
                                                inputProps = {{ maxLength: 70 }}
                                                onChange={(e) => handleInputChange(e, i)}
                                            />
                                        </CngGridItem>

                                        <CngGridItem xs={4} sm={4}>
                                            <IconButton aria-label='copy'>
                                                <FileCopyIcon
                                                    onClick={() => handleCopyReference(i)}
                                                />
                                            </IconButton>
                                            {refIdList.length > 1 && (
                                            <IconButton aria-label='remove'>
                                                <DeleteIcon
                                                    onClick={() => handleRemoveReference(i)}
                                                />
                                            </IconButton>
                                            )}
                                        </CngGridItem>
                                    </Grid>
                                </Card>
                            </CngGridItem>
                        )
                    })}
                </Grid>
            </Box>

            <FooterAddComponent
                handleAddChild={handleAddReference}
                footerText={evgmTranslatedTextsObject.addRefId}
            />
        </Card>
    )
}

const EvgmInvoiceComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default EvgmInvoiceComponent