import { BasePage as CngBasePage } from 'cng-web-lib'
import MyEvgmPage from './myevgm/MyEvgmPage'
import React from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_Evgm'
import TranslationText from './shared/EvgmTranslationText'
import CreateEVGMPage from './createevgm/CreateEVGMPage'
import ReviewEvgmPage from './reviewevgm/ReviewEvgmPage'
import Utils from './shared/Utils'

function WrappedMyEvgmPage(props) {
    const breadcrumbNameMap = useBreadcrumbNameMap()
    const translatedTextsObject = TranslationText()
    return (
        <CngBasePage 
            moduleTitle={translatedTextsObject.title}
            renderPage = {(showSnackbar) => (
                <MyEvgmPage showNotification={showSnackbar} {...props} />
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}

function WrappedCreateEVGMPage(props){
    const breadcrumbNameMap = useBreadcrumbNameMap()
    const translatedTextsObject = TranslationText()
    return(
        <CngBasePage
            moduleTitle={translatedTextsObject.create}
            renderPage = {(showSnackbar) => (
                <CreateEVGMPage 
                    showNotification={showSnackbar} 
                    {...props} 
                />
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}


function WrappedReviewEVGMPage(props){
    const breadcrumbNameMap = useBreadcrumbNameMap()
    const translatedTextsObject = TranslationText()
    return(
        <CngBasePage
            moduleTitle={translatedTextsObject.create}
            renderPage = {(showSnackbar) => (
                <ReviewEvgmPage 
                    showNotification={showSnackbar} 
                    {...props} 
                    prevMenu={Utils.Menus.createEvgm}
                />
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}

export {
    WrappedMyEvgmPage as MyEvgmPage,
    WrappedCreateEVGMPage as CreateEVGMPage,
    WrappedReviewEVGMPage as ReviewEvgmPage,
}