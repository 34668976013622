import {
    Avatar,
    Box,
    Grid,
    Typography
} from '@material-ui/core'

import HtmlTooltip from './HtmlTooltip'
import React from 'react'
import { faAnchor } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const LogoWithTitleVertical = (props) => {
    const { logoSrc, withTooltip, longName, shortName } = props

    return (
        <Grid
            container
            xs={12}
            sm={12}
            direction='column'
            justify='space-between'
            alignItems='center'
            style={{ padding: 5 }}
        >
            <Box pt={0}>
                <Avatar
                    className='avatar'
                    classes={{
                    root: 'cng-summary-card--avatar circle',
                    colorDefault: 'default-bg',
                    img: 'img-src'
                    }}
                    variant='rounded'
                    src={logoSrc}
                >
                    {/* <img className={'search-button'} src={defaultImg}></img> */}
                    <FontAwesomeIcon icon={faAnchor} />
                </Avatar>
            </Box>
            <HtmlTooltip title={withTooltip ? <Box>{longName}</Box> : ''}>
                <Typography
                    variant='h6'
                    style={{
                    textAlign: 'center'
                    }}
                >
                    {shortName}
                </Typography>
            </HtmlTooltip>
        </Grid>
    )
}

export default LogoWithTitleVertical