import useEvgmRefValidationSchema from '../shared/validation/useEvgmRefValidationSchema'
import useEvgmCarrierValidationSchema from '../shared/validation/useEvgmCarrierValidationSchema'
import useEvgmShipperValidationSchema from '../shared/validation/useEvgmShipperValidationSchema'
import useEvgmConsignorValidationSchema from '../shared/validation/useEvgmConsignorValidationSchema'
import useEvgmContainerDetailsValidationSchema from '../shared/validation/useEvgmContainerDetailsValidationSchema'

function useCreateEvgmValidationSchema(){
    return  useEvgmRefValidationSchema()
        .concat(useEvgmCarrierValidationSchema())
        .concat(useEvgmShipperValidationSchema())
        .concat(useEvgmConsignorValidationSchema())
        .concat(useEvgmContainerDetailsValidationSchema())
}

export default useCreateEvgmValidationSchema