import {
    Box,
    Card,
    Grid
} from '@material-ui/core'
import { v4 as uuid } from 'uuid'
import { components } from 'cng-web-lib'
import React, { useEffect, useRef, useState } from 'react'
import EvgmTranslationText from '../shared/EvgmTranslationText'
import EvgmContainerRefComponent from './EvgmContainerRefComponent'
import EvgmContainerSealComponent from './EvgmContainerSealComponent'
import EvgmContainerLocationComponent from './EvgmContainerLocationComponent'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import ContainerTypeAutocompleteField from 'src/components/autocomplete/ContainerTypeAutocompleteField'


const {
    form: {
        adapter: {
        useFormAdapter: { useField, useFormikContext }
        },
        field: {
        CngCodeMasterAutocompleteField,
        CngTextField,
        CngSelectField,
        CngDateTimeField
        }
    },
    CngGridItem,
    table: { useDefaultNotification }
} = components

const initialValues = Object.freeze({
    evgmContainer:{
        containerNo: '',
        containerTypeSize: '',
        supplierCode:'',
        vgm:'',
        vgmUom:'',
        vgmDate:'',
        fullEmptyIndicator:'',
        containerSeals:{
            sealNo: '',
            sealIssuerCode: '',
        },
        references:{
            type: '',
            code: '',
        },
        containerLocations:{
            typeCode: '',
            locCode: '',
            locName: ''
        }
    },
})

const FormBody = (props) => {

    const { setFieldValue } = useFormikContext()
    const [contKey, setContKey] = useState()

    const refNumberKeyRef = useRef(uuid())
    const [refNumberList, setRefNumberList] = useState([])
    
    const sealKeyRef = useRef(uuid())
    const [sealList, setSealList] = useState([])
    const [locationList, setLocationList] = useState([])

    const evgmTranslatedTextsObject = EvgmTranslationText()

    const containerRefNumberItem = {
        type: '',
        code: ''
    }

    const containerSealItem = {
        sealNo: '',
        sealIssuerCode: ''
    }

    const containerLocationItem = {
        typeCode: '',
        locCode: '',
        locName: ''
    }

    const removeRefNumRowClick = (id) => (e) => {

        const list = [...refNumberList]
        list.splice(id, 1)

        refNumberKeyRef.current = uuid()
        setRefNumberList(list)
        setFieldValue('evgmContainer.references', list)

        e.stopPropagation()
    }
    const copyRefNumRowClick = (index) => () => {
        const list = [...refNumberList]
        const copyRefNum = list.slice(index, index + 1)[0]

        const copyRefNumItem = { ...copyRefNum }

        setRefNumberList([...refNumberList, copyRefNumItem])

        setFieldValue('evgmContainer.references', [...refNumberList, copyRefNumItem])
    }
    const handleRefNumberInputChange = (e, index) => {
        const { name, value } = e.target
        const list = [...refNumberList]

        //currently not using
        //list[index][name.split('.')[1]] = value

        console.log(name, " is updating")
        console.log("Value is: ", value)
    
        console.log('check ref list',list)

        setRefNumberList(list)
        setFieldValue('evgmContainer.references', list)
    }
    const handleAddRefNumber = () =>{
        const addRefNumber = {...containerRefNumberItem}
        setRefNumberList([...refNumberList, addRefNumber])
        setFieldValue('evgmContainer.references',[...refNumberList, addRefNumber])
    }


    const removeSealRowClick = (id) => (e) => {

        const list = [...sealList]
        list.splice(id, 1)

        sealKeyRef.current = uuid()
        setSealList(list)
        setFieldValue('evgmContainer.containerSeals', list)

        e.stopPropagation()
    }
    const copySealRowClick = (index) => () => {
        const list = [...sealList]
        const copySealList = list.slice(index, index + 1)[0]

        const copySealItem = { ...copySealList }

        setSealList([...sealList, copySealItem])
        setFieldValue('evgmContainer.containerSeals', [...sealList, copySealItem])
    }
    const handleSealInputChange = (e, index) => {
        const { name, value } = e.target
        const list = [...sealList]

        //currently not using
        //[index][name.split('.')[1]] = value

        console.log(name, " is updating")
        console.log("Value is: ", value)

        setSealList(list)
        setFieldValue('evgmContainer.containerSeals', list)
    }
    const handleAddSeal = () =>{
        const addSeal = {...containerSealItem}
        setSealList([...sealList, addSeal])
        setFieldValue('evgmContainer.containerSeals',[...sealList, addSeal])
    }


    const removeLocationRowClick = (id) => (e) => {

        const list = [...locationList]
        list.splice(id, 1)

        sealKeyRef.current = uuid()
        setLocationList(list)
        setFieldValue('evgmContainer.containerLocations', list)

        e.stopPropagation()
    }
    const copyLocationRowClick = (index) => () => {
        const list = [...locationList]
        const copyLocationList = list.slice(index, index + 1)[0]

        const copyLocationItem = { ...copyLocationList }

        setLocationList([...locationList, copyLocationItem])
        setFieldValue('evgmContainer.containerLocations', [...locationList, copyLocationItem])
    }
    const handleLocationInputChange = (e, index) => {
        const { name, value } = e.target
        const list = [...locationList]

        //currently not using
        //list[index][name.split('.')[1]] = value

        console.log(name, " is updating")
        console.log("Value is: ", value)

        setLocationList(list)
        setFieldValue('evgmContainer.containerLocations', list)
    }
    const handleAddLocation = () =>{
        const addLocation = {...containerLocationItem}
        setLocationList([...locationList, addLocation])
        setFieldValue('evgmContainer.containerLocations',[...locationList, addLocation])
    }

    useEffect(() =>{
        const refNumList ={...containerRefNumberItem}
        const sealListTemp ={...containerSealItem}
        const locationListTemp ={...containerLocationItem}

        if(props.isEdit){
            setContKey(uuid())
            const containerData = props.containerData

            if(props.isCopy){
                setFieldValue('evgmContainer.containerNo', '')
                setFieldValue('evgmContainer.vgm', '')

                if (containerData.containerSeals && containerData.containerSeals.length > 0) {
                    for (let i = 0; i < containerData.containerSeals.length; i++) {
                        props.containerData.containerSeals[i].sealNo = ''
                    }
                    setSealList(props.containerData?.containerSeals)
                    setFieldValue('evgmContainer.containerSeals', props.containerData?.containerSeals)
                }
            }else{
                setFieldValue('evgmContainer.containerNo', containerData.containerNo)
                setFieldValue('evgmContainer.vgm', containerData.vgm)

                if (containerData.containerSeals && containerData.containerSeals.length > 0) {
                    setSealList(props.containerData?.containerSeals)
                    setFieldValue('evgmContainer.containerSeals', props.containerData?.containerSeals)
                }
            }

            
            setFieldValue('evgmContainer.containerTypeSize', containerData.containerTypeSize)
            setFieldValue('evgmContainer.fullEmptyIndicator', containerData.fullEmptyIndicator)
            
            setFieldValue('evgmContainer.vgmUom', containerData.vgmUom)
            setFieldValue('evgmContainer.vgmDate', containerData.vgmDate)

            if (containerData.references && containerData.references.length > 0) {
                setRefNumberList(props.containerData?.references)
                setFieldValue('evgmContainer.references', props.containerData?.references)
            }
            
            if (containerData.containerLocations && containerData.containerLocations.length > 0) {
                setLocationList(props.containerData?.containerLocations)
                setFieldValue('evgmContainer.containerLocations', props.containerData?.containerLocations)
            }
        }else{
            createRefNumberList(refNumList)
            createSealList(sealListTemp)
            createLocationList(locationListTemp)
        }   
    }, [])

    const createRefNumberList = (refNum) => {
        setRefNumberList([refNum])
        setFieldValue('evgmContainer.references', [refNum])
    }

    const createSealList = (seal) => {
        setSealList([seal])
        setFieldValue('evgmContainer.containerSeals', [seal])
    }

    const createLocationList = (loc) => {
        setLocationList([loc])
        setFieldValue('evgmContainer.containerLocations', [loc])
    }


    return(
        <Box>
            <Card>
                <Box px={2} my={1.5}>
                    <Grid container justify='flex-end'>
                        <AccordionHeaderComponent 
                            title = {evgmTranslatedTextsObject.containerDetailsTitle}
                        />
                    </Grid>

                    <Grid container spacing={2} alignItems='center'>

                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type= 'text'
                                name = 'evgmContainer.containerNo'
                                label = {evgmTranslatedTextsObject.containerNumber}
                                inputProps = {{ maxLength: 14 }}
                                required
                            />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}>
                            <ContainerTypeAutocompleteField
                                name='evgmContainer.containerTypeSize'
                                label={evgmTranslatedTextsObject.containerSizeTypeHeight}
                                // onChange={(val, evt) =>
                                // handleDropdownChange(val, evt, i, 'containerIsoType')
                                // }
                                key={contKey}
                                isRequired
                            />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}>
                            <CngCodeMasterAutocompleteField
                                name='evgmContainer.fullEmptyIndicator'
                                label={evgmTranslatedTextsObject.containerStatus}
                                codeType='EVGM_CONTAINER_STATUS'
                                key={contKey}
                                isRequired
                            />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type= 'text'
                                name = 'evgmContainer.vgm'
                                label = {evgmTranslatedTextsObject.vgm}
                                inputProps = {{ maxLength: 18 }}
                                required
                            />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}>
                            <CngCodeMasterAutocompleteField
                                name='evgmContainer.vgmUom'
                                label={evgmTranslatedTextsObject.vgmUom}
                                codeType='EVGM_VGM_UOM'
                                key={contKey}
                                isRequired
                            />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}>
                            <CngDateTimeField
                                name='evgmContainer.vgmDate'
                                label={evgmTranslatedTextsObject.vgmDate}
                                required
                            />
                        </CngGridItem>

                        <CngGridItem xs={12} sm={12}/>

                        <CngGridItem xs={12} sm={12}>
                            <EvgmContainerRefComponent
                                list = {refNumberList}
                                removeRowClick={removeRefNumRowClick}
                                copyRowClick={copyRefNumRowClick}
                                handleInputChange = {handleRefNumberInputChange}
                                addRowClick = {handleAddRefNumber}
                            />
                        </CngGridItem>

                        <CngGridItem xs={12} sm={12}/>

                        <CngGridItem xs={12} sm={12}>
                            <EvgmContainerSealComponent
                                list = {sealList}
                                removeRowClick={removeSealRowClick}
                                copyRowClick={copySealRowClick}
                                handleInputChange = {handleSealInputChange}
                                addRowClick = {handleAddSeal}
                                isCopy = {props.isCopy}
                            />
                        </CngGridItem>

                        <CngGridItem xs={12} sm={12}/>

                        <CngGridItem xs={12} sm={12}>
                            <EvgmContainerLocationComponent
                                list = {locationList}
                                removeRowClick={removeLocationRowClick}
                                copyRowClick={copyLocationRowClick}
                                handleInputChange = {handleLocationInputChange}
                                addRowClick = {handleAddLocation}
                            />
                        </CngGridItem>
                    </Grid>
                </Box>
            </Card>
        </Box>
    )
}

const EvgmContainerDetailsComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default EvgmContainerDetailsComponent