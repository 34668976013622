import { Box, Card, Grid } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import EvgmTranslationText from '../shared/EvgmTranslationText'
import CodeMasterViewField from 'src/components/field/CodeMasterViewField'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'
import CarrierViewField from 'src/components/field/CarrierViewField'

const {
    form: {
      field: { CngSwitchField, CngTextField, CngCodeMasterAutocompleteField }
    },
    CngGridItem
} = components

const FormBody = (props) => {
    
    const evgmTranslatedTextsObject = EvgmTranslationText()

    return(
        <Grid container xs={12} sm={12}>
            <CngGridItem xs={12} sm={12}>
                <Card>
                    <Box className='view-card-header'>
                        <Grid container xs={12} sm={12}>
                            <CngGridItem xs={6} sm={6}>
                                {evgmTranslatedTextsObject.carrierInfoTitle}
                            </CngGridItem>
                        </Grid>
                    </Box>

                    <Box>
                        <Grid container xs={12} sm={12}>

                            <CngGridItem xs={12} sm={4}>
                                <Box className='view-card-content'>
                                    <Box className='view-card-content-label'>
                                    {evgmTranslatedTextsObject.carrierPartyId}
                                    </Box>
                                    <Box
                                    className='view-card-content-value'
                                    style={{
                                        wordBreak: 'break-word',
                                        whiteSpace: 'pre-line'
                                    }}
                                    >
                                    
                                    <CarrierViewField
                                        carrierPartyId={props.carrierData[0]?.partyId || ''}
                                    />
                                    </Box>
                                </Box>
                            </CngGridItem>

                            <CngGridItem xs={4} sm={4}>
                                <Box className='view-card-content'>
                                    <Box className='view-card-content-label'>
                                        {evgmTranslatedTextsObject.partyIdType}
                                    </Box>
                                    <Box className='view-card-content-value'>
                                        <CodeMasterViewField
                                            codeType='EVGM_PARTY_ID_TYPE'
                                            code={props.carrierData[0]?.partyIdType  || ''}
                                        />
                                    </Box>
                                </Box>
                            </CngGridItem>

                            <CngGridItem xs={4} sm={4}>
                                <Box className='view-card-content'>
                                    <Box className='view-card-content-label'>
                                        {evgmTranslatedTextsObject.agencyCode}
                                    </Box>
                                    <Box className='view-card-content-value'>
                                        <CodeMasterViewField
                                            codeType='EVGM_AGENCY_CODE'
                                            code={props.carrierData[0]?.agencyCode  || ''}
                                        />
                                    </Box>
                                </Box>
                            </CngGridItem>

                            <CngGridItem xs={4} sm={4}>
                                <Box className='view-card-content'>
                                    <Box className='view-card-content-label'>
                                        {evgmTranslatedTextsObject.carrierContactType}
                                    </Box>
                                    <Box className='view-card-content-value'>
                                        <CodeMasterViewField
                                            codeType='EVGM_CONTACT_TYPE'
                                            code={props.carrierData[0]?.partyContacts[0]?.contactType  || ''}
                                        />
                                    </Box>
                                </Box>
                            </CngGridItem>

                            <CngGridItem xs={4} sm={4}>
                                <LabelValueVertical
                                    label={evgmTranslatedTextsObject.contactName}
                                    value={props.carrierData[0]?.partyContacts[0]?.contactName  || ''}
                                />
                            </CngGridItem>

                            <CngGridItem xs={4} sm={4}>
                                <LabelValueVertical
                                    label={evgmTranslatedTextsObject.contactAddress}
                                    value={props.carrierData[0]?.address?.streetAddress  || ''}
                                />
                            </CngGridItem>

                            <CngGridItem xs={4} sm={4}/>

                            <CngGridItem xs={4} sm={4}>
                                <LabelValueVertical
                                    label={evgmTranslatedTextsObject.phoneNumber}
                                    value={props.carrierData[0]?.partyContacts[0]?.contactCommunication[0]?.commNo  || ''}
                                />
                            </CngGridItem>

                            <CngGridItem xs={4} sm={4}>
                                <LabelValueVertical
                                    label={evgmTranslatedTextsObject.emailAddress}
                                    value={props.carrierData[0]?.partyContacts[0]?.contactCommunication[1]?.commNo  || ''}
                                />
                            </CngGridItem>
                        </Grid>
                    </Box>
                </Card>
            </CngGridItem>
        </Grid>

    )
}

const initialValues = Object.freeze({})

const ViewEvgmCarrierComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ViewEvgmCarrierComponent