import pathMap from 'src/paths/PathMap_Evgm'
import { components, useServices } from 'cng-web-lib'
import { useHistory} from 'react-router-dom'
import React, { useState } from 'react'
import EVGMApiUrls from 'src/apiUrls/EvgmApiUrls'
import CommonUtils from 'src/views/common/utils/Utils'
import ActionMenuEvgmList from './ActionMenuEvgmList'
import Utils from '../shared/Utils'


const {
    button: { CngPrimaryButton, CngSecondaryButton },
    table: { useDefaultNotification }
} = components

function EvgmActionMenu(props){

    const history = useHistory()
    const {evgm} = props
    const { createRecord } = useServices()
    const [, setConfirmDialogOpen] = useState(false)
    const { showNotification } = props
    const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

    let actions = Utils.getEvgmStatusById(evgm.evgmStatus).actions

    console.log('check evgm', evgm)

    function onError(error) {
        console.log('connection-request error', error.message)
        showErrorNotification(CommonUtils.getErrorMessage(error))
    }

    const copyEvgm = () => {
        setConfirmDialogOpen(false) 

        function onSuccess(response){
            console.log('connection-request onSuccess', response)
            console.log(response)
            if (response.errorMessages != null) {
                showErrorNotification(response.errorMessages)
            } else {
                showSuccessNotification(
                `${'Successfully copied EVGM'}`
                )
                    history.push({
                    pathname: pathMap.CREATE_NEW_EVGM,
                    state: { data: response, action: 'copy', isEdit: true, isCopy: true }
                })
            }
        }

        createRecord.execute(
            EVGMApiUrls.COPY_EVGM,
            { 
                evgmId: evgm.evgmId,
                //id: evgm.id
            },
            onSuccess,
            onError
        )

    }

    const eventHandlers = {
        copyEvgm
    }

    return(
        <div>
            <ActionMenuEvgmList
                actions={actions}
                eventHandlers={eventHandlers}
            />
        </div>
    )
}

export default EvgmActionMenu