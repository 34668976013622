import {
    Box,
    Card,
    Grid
} from '@material-ui/core'
import { v4 as uuid } from 'uuid'
import React, { useEffect, useState } from 'react'
import { components, constants } from 'cng-web-lib'
import EvgmTranslationText from '../shared/EvgmTranslationText'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'

const {
    form: {
        adapter: {
            useFormAdapter: { useField, useFormikContext }
        },
        field: {
            CngTextField,
            CngSelectField,
            CngDateField,
            CngCodeMasterAutocompleteField,
        },
    },
    CngGridItem,
} = components

const {
    component: {
        textField: {
            customType: { PLAIN_NUMBER }
        }
    }
} = constants

const EvgmDocumentInfoComponent = (props) => {

    const [contKey, setContKey] = useState()
    const evgmTranslatedTextsObject = EvgmTranslationText()
    const { setFieldValue } = useFormikContext()

    useEffect(() =>{
        console.log('props.isEdit', props.isEdit)


        if(props.isEdit){
            setContKey(uuid())
            const containerInfo = props.list[0]

            setFieldValue('evgmContainer.containerDocument.docInformation[0].infoType', containerInfo?.infoType || '')
            setFieldValue('evgmContainer.containerDocument.docInformation[0].name', containerInfo?.name || '')
            setFieldValue('evgmContainer.containerDocument.docInformation[0].address.streetAddress', containerInfo?.streetAddress || containerInfo?.address?.streetAddress || '')
            setFieldValue('evgmContainer.containerDocument.docInformation[0].contactInformation[0].contactType', containerInfo?.contactInformation?.[0]?.contactType || '')
            setFieldValue('evgmContainer.containerDocument.docInformation[0].contactInformation[0].contactName', containerInfo?.contactInformation?.[0]?.contactName || '')
            setFieldValue('evgmContainer.containerDocument.docInformation[0].contactInformation[0].communicationInfo[0].commType', containerInfo?.contactInformation?.[0]?.communicationInfo[0]?.commType || '')
            setFieldValue('evgmContainer.containerDocument.docInformation[0].contactInformation[0].communicationInfo[0].commNo', containerInfo?.contactInformation?.[0]?.communicationInfo[0]?.commNo || '')
        }
        
    }, [])

    return(
        <Card>
            <Box px={2} my={1.5}>
                <Grid container>
                    <AccordionHeaderComponent
                        title={evgmTranslatedTextsObject.ctnDocInfoTitle}
                    />
                </Grid>

                <Grid container spacing={2}>
                    {
                        props.list.map((m, idx) => (
                            <CngGridItem xs={12} sm={12} key={m.id}>
                                <Card className='inner-card-group'>
                                    <Grid container xs={12} sm={12} spacing={2}> 
                                        <CngGridItem xs={4} sm={4}>  
                                            <CngCodeMasterAutocompleteField
                                                name={`evgmContainer.containerDocument.docInformation[${idx}].infoType`}
                                                label={evgmTranslatedTextsObject.ctnDocType}
                                                codeType='EVGM_DOC_TYPE'
                                                key={contKey}
                                                required
                                            />
                                        </CngGridItem>

                                        <CngGridItem xs={4} sm={4}>
                                            <CngTextField
                                                type='text'
                                                name={`evgmContainer.containerDocument.docInformation[${idx}].name`}
                                                label={evgmTranslatedTextsObject.ctnDocPartyName}
                                                value={m?.name || ''}
                                                inputProps={{ maxLength: 350 }}
                                                onChange={e => props.handleNameInputChange(e, idx)}
                                            />
                                        </CngGridItem>

                                        <CngGridItem xs={4} sm={4}>
                                            <CngTextField
                                                type='text'
                                                name={`evgmContainer.containerDocument.docInformation[${idx}].address.streetAddress`}
                                                label={evgmTranslatedTextsObject.ctnDocPartyAddress}
                                                value={m?.address?.streetAddress || m?.streetAddress || ''}
                                                inputProps={{ maxLength: 140 }}
                                                onChange={e => props.handleAddressInputChange(e, idx)}
                                            />
                                        </CngGridItem>
        
                                        <CngGridItem xs={4} sm={4}>  
                                            <CngCodeMasterAutocompleteField
                                                name={`evgmContainer.containerDocument.docInformation[${idx}].contactInformation[0].contactType`}
                                                label={evgmTranslatedTextsObject.ctnDocContactType}
                                                codeType='EVGM_DOC_CONTACT_TYPE'
                                                key={contKey}
                                            />
                                        </CngGridItem>
                                        <CngGridItem xs={4} sm={4}>
                                            <CngTextField
                                                type='text'
                                                name={`evgmContainer.containerDocument.docInformation[${idx}].contactInformation[0].contactName`}
                                                label={evgmTranslatedTextsObject.ctnDocContactName}
                                                value={m?.contactInformation?.[0]?.contactName || ''}
                                                inputProps={{ maxLength: 256 }}
                                                onChange={e => props.handleAddressInputChange(e, idx)}
                                            />
                                        </CngGridItem>
        
                                        <CngGridItem xs={4} sm={4}/>
        
                                        <CngGridItem xs={4} sm={4}>  
                                            <CngCodeMasterAutocompleteField
                                                name={`evgmContainer.containerDocument.docInformation[${idx}].contactInformation[0].communicationInfo[0].commType`}
                                                label={evgmTranslatedTextsObject.ctnDocCommType}
                                                codeType='EVGM_COMM_TYPE'
                                                key={contKey}
                                            />
                                        </CngGridItem>
                                        <CngGridItem xs={4} sm={4}>
                                            <CngTextField
                                                type='text'
                                                name={`evgmContainer.containerDocument.docInformation[${idx}].contactInformation[0].communicationInfo[0].commNo`}
                                                label={evgmTranslatedTextsObject.ctnDocCommValue}
                                                value={m?.contactInformation?.[0]?.communicationInfo?.[0]?.commNo || ''}
                                                inputProps={{ maxLength: 512 }}
                                                onChange={e => props.handleCommInputChange(e, idx)}
                                            />
                                        </CngGridItem>
                                    </Grid>
                                </Card>
                            </CngGridItem>
                        ))
                    }
                </Grid>
            </Box>
        </Card>
    )
}

export default EvgmDocumentInfoComponent