import {
    Box,
    Typography
  } from '@material-ui/core'
  
  import ManageScheduleTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
  import React from 'react'
  import StatusCheckboxGroup from 'src/views/shippinginstruction/components/StatusCheckboxGroup'
  
  const StatusFilterComponent = (props) => {
    const translatedTextsObject = ManageScheduleTranslationText()
  
    return (
      <section className={'cng-filter-pane--section-container'}>
        <header>
          <Typography className={'cng-filter-pane--section-header-label'}>
            {translatedTextsObject.status}
          </Typography>
        </header>
        <article>
          <Box className={`cng-filter-pane--controls-wrapper`}>
            <StatusCheckboxGroup
              checkBoxList={props.status}
              onChange={props.onStatusCheckBoxChange}
              filterName={''}
            />
          </Box>
        </article>
      </section>
    )
}
  
export default StatusFilterComponent
  