import { Card, CardContent, Grid } from '@material-ui/core'

import CarrierFilterComponents from './CarrierFilterComponent'
import DateRangeFilterComponent from './DateRangeFilterComponent'
import FilterFooterComponent from './FilterFooterComponent'
import FilterHeaderComponent from './FilterHeaderComponent'
import React from 'react'
import StatusFilterComponent from './StatusFilterComponent'
import { components } from 'cng-web-lib'

const {
    form: {
      field: { CngSwitchField }
    },
    CngGridItem
} = components

function FilterPanelComponent(props) {
    return (
      <Grid md={12}>
        <Card>
          <CardContent className={`cng-filter-pane--wrapper`}>
            <FilterHeaderComponent
              handleClearAllButtonClick={props.handleClearAllButtonClick}
            />
  
            <DateRangeFilterComponent
              dateRangeChange={props.dateRangeChange}
              dateRange={props.dateRange}
            />
  
            <StatusFilterComponent
              directOnly={props.directOnly}
              directOnlyCount={2}
              status={props.status}
              onStatusCheckBoxChange={props.onStatusCheckBoxChange}
            />
  
            {!props.isCarrier && !props.helpdesk ? (
              <CarrierFilterComponents
                carriers={props.carriers}
                onCarrierCheckBoxChange={props.onCarrierCheckBoxChange}
              />
            ) : (
              ''
            )}
  
            <FilterFooterComponent
            />
          </CardContent>
        </Card>
      </Grid>
    )
}
  
export default FilterPanelComponent