import {
    Box,
    Card,
    CardActionArea,
    Grid,
    IconButton
} from '@material-ui/core'

import EvgmDetailBottom from './EvgmDetailBottom'
import EvgmDetailTop from './EvgmDetailTop'
import EvgmTranslationText from '../shared/EvgmTranslationText'
import LabelValueVerticalSimple from './LabelValueVerticalSimple'
import LogoWithTitleVertical from './LogoWithTitleVertical'
import React from 'react'
import { components } from 'cng-web-lib'
import { withStyles } from '@material-ui/core/styles'

const { CngGridItem } = components

const initialValues = Object.freeze({})

const InfoIconButton = withStyles((theme) => ({
    root: {
      padding: 0
    }
}))(IconButton)

const FormBody = (props) => {
    const translatedTextsObject = EvgmTranslationText()
    const { evgm } = props

    let carrierShortName = evgm.carrierPartyName
    let isCarrierNameCut = false
    if (evgm.carrierPartyName && evgm.carrierPartyName.length > 5) {
        carrierShortName = evgm.carrierPartyName.substring(0, 5).trim() + '...'
        isCarrierNameCut = true
    }

    return (
        <Card className={`summary-list-item--card`}>
            <EvgmDetailTop evgm = {evgm} {...props} />
            <CardActionArea
                /* 
                Do nothing for now as detail page is not done yet 
                onClick={gotoEvgmInfo}
                */
                className={`cng-summary-card--body-box`}
            >
                <Grid container className={`cng-summary-card--body-grid`}>
                    <CngGridItem xs={12} sm={1}>
                        <LogoWithTitleVertical
                            logoSrc={`${process.env.PUBLIC_URL}/static/images/carriers/${evgm.carrierPartyNameOth}.svg`}
                            defaultImg={`${process.env.PUBLIC_URL}/static/images/carriers/genericCarrier.svg`}
                            withTooltip={isCarrierNameCut}
                            longName={evgm.carrierPartyName}
                            shortName={carrierShortName}
                        />
                    </CngGridItem>
        
                    <CngGridItem xs={12} sm={5} style={{ paddingLeft: 20 }}>
                        <Box pt={0.5}>
                            <LabelValueVerticalSimple
                                label={translatedTextsObject.containerNo}
                                value={evgm.containerNo}
                                defaultValue='N/A'
                                labelStyle={'frb-card-label'}
                                valueStyle={'frb-card-content'}
                            />
                        </Box>
                        <Box pt={0.5}>
                            <Grid
                                container
                                direction='row'
                                justify='flex-start'
                                alignItems='center'
                            >
                                <CngGridItem sm={4}>
                                    <LabelValueVerticalSimple
                                        label={translatedTextsObject.containerType}
                                        value={evgm.containerType}
                                        defaultValue='N/A'
                                        labelStyle={'frb-card-label'}
                                        valueStyle={'frb-card-content'}
                                    />
                                </CngGridItem>
                            </Grid>
                        </Box>
        
                        <Box pt={0.5}>
                            <LabelValueVerticalSimple
                                label={translatedTextsObject.verifiedGrossMass}
                                value={evgm.verifiedGrossMass}
                                defaultValue='N/A'
                                labelStyle={'frb-card-label'}
                                valueStyle={'frb-card-content font-mediumBold'}
                            />
                        </Box>
                    </CngGridItem>
        
                    <CngGridItem xs={12} sm={6}>
                        <Grid container>
                            <CngGridItem xs={12} sm={12}>
                                <Grid container>
                                    <CngGridItem xs={12} sm={6}>
                                        <LabelValueVerticalSimple
                                            label={translatedTextsObject.freightForwarder}
                                            value={evgm.freightForwarderName}
                                            defaultValue='N/A'
                                            labelStyle={'frb-card-label'}
                                            valueStyle={'frb-card-content font-mediumBold'}
                                        />
                                    </CngGridItem>
                
                                    <CngGridItem xs={12} sm={6}>
                                        <LabelValueVerticalSimple
                                            label={translatedTextsObject.consignor}
                                            value={evgm.consignor}
                                            defaultValue='N/A'
                                            labelStyle={'frb-card-label'}
                                            valueStyle={'frb-card-content font-mediumBold'}
                                        />
                                    </CngGridItem>
                                </Grid>
                            </CngGridItem>
                            <CngGridItem xs={12} sm={12}>
                                <Grid container>
                                    <CngGridItem xs={12} sm={6}>
                                        <LabelValueVerticalSimple
                                            label={translatedTextsObject.bookingNumber}
                                            value={evgm.bookingNo}
                                            defaultValue='N/A'
                                            labelStyle={'frb-card-label'}
                                            valueStyle={'frb-card-content font-mediumBold'}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={6}>
                                        <LabelValueVerticalSimple
                                            label={translatedTextsObject.shipperIdentifyingNumber}
                                            value={evgm.shipperIdentifyingNo}
                                            defaultValue='N/A'
                                            labelStyle={'frb-card-label'}
                                            valueStyle={'frb-card-content font-mediumBold'}
                                        />
                                    </CngGridItem>
                                </Grid>
                            </CngGridItem>
                            <CngGridItem xs={12} sm={12}>
                                <Grid container>
                                    <CngGridItem xs={12} sm={6}>
                                        <LabelValueVerticalSimple
                                            label={translatedTextsObject.docId}
                                            value={evgm.docID}
                                            defaultValue='N/A'
                                            labelStyle={'frb-card-label'}
                                            valueStyle={'frb-card-content font-mediumBold'}
                                        />
                                    </CngGridItem>
                                </Grid>
                            </CngGridItem>
                        </Grid>
                    </CngGridItem>
                </Grid>
            </CardActionArea>
            <EvgmDetailBottom
                evgmContainerId={evgm.evgmContainerID}
                updatedDate={evgm.updatedDate}
            />
        </Card>
    )
}

const EvgmDetailComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default EvgmDetailComponent