import { Box, Card, Grid } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import EvgmTranslationText from '../shared/EvgmTranslationText'
import CodeMasterViewField from 'src/components/field/CodeMasterViewField'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'
import ViewEvgmContainerRefComponent from './ViewEvgmContainerRefComponent'
import ViewEvgmContainerSealComponent from './ViewEvgmContainerSealComponent'
import ViewEvgmContainerLocationComponent from './ViewEvgmContainerLocationComponent'

const {
    CngGridItem
} = components

const FormBody = (props) => {

    const evgmTranslatedTextsObject = EvgmTranslationText()

    return(
        <Grid container xs={12} sm={12}>
             <CngGridItem xs={12} sm={12}>
                <Card>
                    <Box className='view-card-header'>
                        <Grid container xs={12} sm={12}>
                            <CngGridItem xs={6} sm={6}>
                                {evgmTranslatedTextsObject.containerDetailsTitle}
                            </CngGridItem>
                        </Grid>
                    </Box>

                    <Box>
                        <Grid container xs={12} sm={12}>

                            <CngGridItem xs={4} sm={4}>
                                <LabelValueVertical
                                    label={evgmTranslatedTextsObject.containerNo}
                                    value={props.containerData?.containerNo}
                                />
                            </CngGridItem>

                            <CngGridItem xs={4} sm={4}>
                            <LabelValueVertical
                                    label={evgmTranslatedTextsObject.containerSizeTypeHeight}
                                    value={props.containerData?.containerTypeSize}
                                />
                            </CngGridItem>

                            <CngGridItem xs={4} sm={4}>
                                <Box className='view-card-content'>
                                    <Box className='view-card-content-label'>
                                        {evgmTranslatedTextsObject.containerStatus}
                                    </Box>
                                    <Box className='view-card-content-value'>
                                        <CodeMasterViewField
                                            codeType='EVGM_CONTAINER_STATUS'
                                            code={props.containerData?.fullEmptyIndicator}
                                        />
                                    </Box>
                                </Box>
                            </CngGridItem>

                            <CngGridItem xs={4} sm={4}>
                                <LabelValueVertical
                                    label={evgmTranslatedTextsObject.vgm}
                                    value={props.containerData?.vgm}
                                />
                            </CngGridItem>

                            <CngGridItem xs={4} sm={4}>
                                <Box className='view-card-content'>
                                    <Box className='view-card-content-label'>
                                        {evgmTranslatedTextsObject.vgmUom}
                                    </Box>
                                    <Box className='view-card-content-value'>
                                        <CodeMasterViewField
                                            codeType='EVGM_VGM_UOM'
                                            code={props.containerData?.vgmUom}
                                        />
                                    </Box>
                                </Box>
                            </CngGridItem>

                            <CngGridItem xs={4} sm={4}>
                                <LabelValueVertical
                                    label={evgmTranslatedTextsObject.vgmDate}
                                    value={props.containerData?.vgmDate}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={12}/>
                                
                            <CngGridItem xs={12} sm={12}>
                                <ViewEvgmContainerRefComponent.FormBody
                                    containerRefData = {props.containerData?.references}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={12}/>
                                
                            <CngGridItem xs={12} sm={12}>
                                <ViewEvgmContainerSealComponent.FormBody
                                    containerSealData = {props.containerData?.containerSeals}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={12}/>

                            <CngGridItem xs={12} sm={12}>
                                <ViewEvgmContainerLocationComponent.FormBody
                                    containerLocData = {props.containerData?.containerLocations}
                                />
                            </CngGridItem>

                        </Grid>
                    </Box>
                </Card>
             </CngGridItem>
        </Grid>
    )
}

const initialValues = Object.freeze({})

const ViewEvgmContainerDetailsComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ViewEvgmContainerDetailsComponent