import { Box, Card, Grid } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import EvgmTranslationText from '../shared/EvgmTranslationText'
import { v4 as uuid } from 'uuid'
import CodeMasterViewField from 'src/components/field/CodeMasterViewField'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'

const {
    form: {
      field: { CngSwitchField, CngTextField, CngCodeMasterAutocompleteField }
    },
    CngGridItem
} = components

const FormBody = (props) => {

    const evgmTranslatedTextsObject = EvgmTranslationText()

    return(
        <Grid container xs={12} sm={12}>
             <CngGridItem xs={12} sm={12}>
                <Card>
                    <Box className='view-card-header'>
                        <Grid container xs={12} sm={12}>
                            <CngGridItem xs={6} sm={6}>
                                {evgmTranslatedTextsObject.ctnSealsInfoTitle}
                            </CngGridItem>
                        </Grid>
                    </Box>

                    <Box>
                        {props.containerSealData ? (
                            props.containerSealData.map((row) => {
                                const rowKey = uuid() 

                                return(
                                    <Grid container key={rowKey} xs={12} sm={12}>

                                        <CngGridItem xs={4} sm={4}>
                                            <Box className='view-card-content'>
                                                <Box className='view-card-content-label'>
                                                    {evgmTranslatedTextsObject.ctnSealsInfoCode}
                                                </Box>
                                                <Box className='view-card-content-value'>
                                                    <CodeMasterViewField
                                                        codeType='EVGM_SEAL_ISSUE_CODE'
                                                        code={row.sealIssuerCode}
                                                    />
                                                </Box>
                                            </Box>
                                        </CngGridItem>

                                        <CngGridItem xs={4} sm={4}>
                                            <LabelValueVertical
                                                label={evgmTranslatedTextsObject.ctnSealsInfoNumber}
                                                value={row.sealNo}
                                            />
                                        </CngGridItem>
                                    </Grid>
                                )
                            })
                            ):(
                                <></>
                            )
                        }
                    </Box>
                </Card>
            </CngGridItem>
        </Grid>
    )
}

const initialValues = Object.freeze({})

const ViewEvgmContainerSealComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ViewEvgmContainerSealComponent