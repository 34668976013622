import React, { useState } from 'react'
import pathMap from 'src/paths/PathMap_Evgm'
import EVGMApiUrls from 'src/apiUrls/EvgmApiUrls'
import { components, useServices } from 'cng-web-lib'
import CommonUtils from 'src/views/common/utils/Utils'
import { useHistory } from 'react-router-dom'
import EvgmTranslationText from '../shared/EvgmTranslationText'
import CreateEVGMFormProperties from './CreateEVGMFormProperties'

const {
    form: { CngViewForm },
    table: { useDefaultNotification }
} = components

function CreateEVGMPage({showNotification}){

    const history = useHistory()
    const { createRecord } = useServices()
    const [loading, setLoading] = useState(false)
    const [recalculate, setRecalculate] = useState(0)
    const evgmTranslatedTextsObject = EvgmTranslationText()
    const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

    function onError(error){
        console.log('connection-request error', error.message)
        setLoading(false)
        setRecalculate(recalculate + 1)
        showErrorNotification(CommonUtils.getErrorMessage(error))
    }

    function saveDraft(data){
        setLoading(true)
        console.log('saveDraft')
        function onSuccess(response) {
            console.log('connection-request onSuccess', response)
            setLoading(false)
            
            if (response.errorMessages != null) {
                setRecalculate(recalculate + 1)
                showErrorNotification(response.errorMessages)
              } else {
                showSuccessNotification(
                  `${evgmTranslatedTextsObject.draftSuccessMsg}`
                )
            }
        }

        createRecord.execute(
            EVGMApiUrls.SAVE_DRAFT,
            data,
            onSuccess,
            onError
        )
    }

    function CreateReviewEvgm(data){
        setLoading(true)
        console.log('ReviewEvgm')

        function onSuccess(response){
            console.log('connection-request onSuccess', response)
            setLoading(false)
            if (response.errorMessages != null) {
                setRecalculate(recalculate + 1)
                showErrorNotification(response.errorMessages)
            } else {
                redirectToReviewPage(data)
            }
        }

        createRecord.execute(
            EVGMApiUrls.REVIEW,
            data,
            onSuccess,
            onError
        )
    }

    function redirectToReviewPage(data){
        console.log('redirectToReviewPage', data)
  
        history.push({
            pathname: pathMap.REVIEW_EVGM,
            state:
            {
                data,
            }
        })
    }

    function onSubmit(data) {
        console.log('submit!', data)

        if(data['saveDraft']){
            console.log('Save draft!')
            saveDraft(data) //test save draft
        }
        else{
            console.log('Go to review page!')
            CreateReviewEvgm(data)
        }
    }

    return (
        // use CngViewForm here for customizable button set
        <CngViewForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            history={history}
            showNotification={showNotification}
            bodySection={
                <CreateEVGMFormProperties.FormFields
                    showNotification={showNotification}
                    loading={loading}
                    setLoading={setLoading}
                    onSubmitForm={onSubmit}
                />
            }
            formikProps={{
                ...CreateEVGMFormProperties.formikProps,
                onSubmit: onSubmit
            }}
            // toClientDataFormat={PollingFormProperties.toClientDataFormat}
            // toServerDataFormat={PollingFormProperties.toServerDataFormat}
        />
    )
}

export default CreateEVGMPage