import { useFormContext } from 'react-hook-form'
import pathMap from 'src/paths/PathMap_Evgm'
import { components} from 'cng-web-lib'
import { useHistory, useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import WarningDialog from 'src/components/dialog/WarningDialog'
import { Box, Paper } from '@material-ui/core'
import EvgmTranslationText from '../shared/EvgmTranslationText'
import GeneralStepperComponent from 'src/views/common/ui/GeneralStepperComponent'
import EvgmInvoiceComponent from '../components/EvgmInvoiceComponent'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import EvgmCarrierComponent from '../components/EvgmCarrierComponent'
import EvgmShipperComponent from '../components/EvgmShipperComponent'
import EvgmButtonComponent from '../components/EvgmButtonComponent'
import EvgmConsignorComponent from '../components/EvgmConsignorComponent'
import EvgmContainerDocumentComponent from '../components/EvgmContainerDocumentComponent'
import EvgmContainerDetailsComponent from '../components/EvgmContainerDetailsComponent'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import useCreateEvgmValidationSchema from './useCreateEvgmValidationSchema'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'

const {
    form: {
        adapter: {
        useFormAdapter: { useField, useFormikContext }
        },
        field:{CngCheckboxField}
    },

    table: { useDefaultNotification },
    CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
    ...EvgmInvoiceComponent.initialValues,
    ...EvgmCarrierComponent.initialValues,
    ...EvgmShipperComponent.initialValues,
    ...EvgmConsignorComponent.initialValues,
    ...EvgmContainerDetailsComponent.initialValues,
    ...EvgmContainerDocumentComponent.initialValues,
})

const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function FormFields({
    showNotification,
    loading,
    setLoading,
    onSubmitForm
}){
    const history = useHistory()
    const location = useLocation()
    const stateData = location.state
    const { setFieldValue } = useFormikContext()
    const { getValues, setError} = useFormContext()

    const evgmTranslatedTextsObject = EvgmTranslationText()
    const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
    const fbTranslatedTextsObject = FreightBookingTranslationText()

    const [, setEvgmReference] = useState([])
    const [, setEvgmParties] = useState([])
    const [, setEvgmContainer] = useState([])
    const [, setFromReview] = useState(false)

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const [warningDialogOpen, setWarningDialogOpen] = useState(false)

    const neweVGMSteps = [
        evgmTranslatedTextsObject.stepLabelFillupDetails,
        evgmTranslatedTextsObject.stepLabelReviewDetails,
        evgmTranslatedTextsObject.stepLabelSubmit
    ]

    const validationSchema = useCreateEvgmValidationSchema()

    const transformParty = (partyData) => {
        return {
          role: partyData.role,
          partyId: partyData.partyIdCode,
          partyName: partyData.partyName,
          partyIdType: partyData.partyIdType,
          agencyCode: partyData.agencyCode,
          address: {
            streetAddress: partyData.contactAddress,
            // Fill in the city, postal code, country as needed
            cityName: partyData.cityName,
            postalCode: partyData.postalCode,
            countryCode: partyData.countryCode,
            countryName: partyData.countryName
          },
          partyContacts:[
                {
                    contactType: partyData.contactType,
                    contactName: partyData.contactName,
                    contactCommunication:[
                        {
                            commType: "TE",
                            commNo: partyData.phoneNumber
                        },
                        {
                            commType: "EM",
                            commNo: partyData.emailAddress,
                        }
                    ]
                }
            ]
        }
    }

    const transformAndDeleteParty = (role, values) => {
        const roleMap = {
            'carrier': 'CA',
            'shipper': 'FW',
            'consignor': 'CZ'
        }
    
        const transformedParty = {
            role: roleMap[role], // Map the role to the corresponding code
            partyName: values[`${role.toLowerCase()}PartyName`],
            partyIdCode: values[`${role.toLowerCase()}PartyIdCode`],
            partyIdType: values[`${role.toLowerCase()}PartyIdType`],
            agencyCode: values[`${role.toLowerCase()}AgencyCode`],
            contactName: values[`${role.toLowerCase()}ContactName`],
            contactType: values[`${role.toLowerCase()}ContactType`],
            emailAddress: values[`${role.toLowerCase()}EmailAddress`],
            contactAddress: values[`${role.toLowerCase()}ContactAddress`],
            cityName: values[`${role.toLowerCase()}AddressCityName`],
            postalCode: values[`${role.toLowerCase()}AddressPostalCode`],
            countryCode: values[`${role.toLowerCase()}AddressCountryCode`],
            countryName: values[`${role.toLowerCase()}AddressCountryName`],
            phoneNumber: values[`${role.toLowerCase()}PhoneNumber`],
        }
    
        // Delete the original fields for the party
        delete values[`${role.toLowerCase()}PartyName`]
        delete values[`${role.toLowerCase()}PartyIdType`]
        delete values[`${role.toLowerCase()}PartyIdCode`]
        delete values[`${role.toLowerCase()}AgencyCode`]
        delete values[`${role.toLowerCase()}ContactName`]
        delete values[`${role.toLowerCase()}EmailAddress`]
        delete values[`${role.toLowerCase()}ContactAddress`]
        delete values[`${role.toLowerCase()}AddressCityName`]
        delete values[`${role.toLowerCase()}AddressPostalCode`]
        delete values[`${role.toLowerCase()}AddressCountryCode`]
        delete values[`${role.toLowerCase()}AddressCountryName`]
        delete values[`${role.toLowerCase()}ContactType`]
        delete values[`${role.toLowerCase()}PhoneNumber`]
        
        return transformedParty
    }

    const onSaveDraft = async () =>{
        await setFieldValue('saveDraft', true)

        if(onSubmitForm){
            const values = getValues()

            console.log('values before', values)
            
            // Transform and delete fields for each party role
            const transformedCarrier = transformParty(transformAndDeleteParty('carrier', values))
            const transformedShipper = transformParty(transformAndDeleteParty('shipper', values))
            const transformedConsignor = transformParty(transformAndDeleteParty('consignor', values))

            const parties = [transformedCarrier, transformedConsignor, transformedShipper]

            //update the values object with the transformed party
            values.parties = parties

            console.log('transformed values', values)

            //currently no validation so submit the current values
            onSubmitForm(values)
        }
    }

    const onConfirmReview = async () =>{
        console.log('confirm review')

        if(stateData.isCopy){
            await setFieldValue('copyEVGM', true)
        }else{
            await setFieldValue('copyEVGM', false)
        }
        

        if(onSubmitForm){
            const values = getValues()

            console.log('values before', values)
            //currently no validation so submit the current values
            // onSubmitForm(values)

            try {
                await validationSchema.validate(values)
          
                // Transform and delete fields for each party role
                const transformedCarrier = transformParty(transformAndDeleteParty('carrier', values))
                const transformedShipper = transformParty(transformAndDeleteParty('shipper', values))
                const transformedConsignor = transformParty(transformAndDeleteParty('consignor', values))

                const parties = [transformedCarrier, transformedConsignor, transformedShipper]

                //update the values object with the transformed party
                values.parties = parties
                console.log('transformed values', values)

                //Send frb id to the payload if its copy evgm
                if(stateData.isCopy){
                    values.evgmId = stateData.data.evgmId
                    values.id = stateData.data.id
                }

                onSubmitForm(values)
            } catch (error) {
                console.log('error', error);
                setValidatedError(error.path, error.message)
                setWarningDialogOpen(true)
            }
        }
    }

    const onDiscard = () => {
        setConfirmDialogOpen(true)
    }

    const discardEvgm = () => {
        history.push({
            pathname: pathMap.MY_EVGM
        })
    }

    const setValidatedError = (errorPath, message) => {
        let finalError = { message: message }
        setError(errorPath, finalError)
    }

    function populateDataFromReviewEvgm(){
        setEvgmContainer(stateData.data?.evgmContainer)
        setEvgmParties(stateData.data?.parties)
        setEvgmReference(stateData.data.references)
    }

    useEffect(() => {
        console.log('Check data', stateData)

        if(stateData != null ){
            if(stateData.isEdit){

                setFromReview(true)
                populateDataFromReviewEvgm()
                
                console.log('Check carried data container', stateData.data?.evgmContainer)
                console.log('Check carried data parties', stateData.data?.parties)
                console.log('Check carried data ref', stateData.data?.references)
            }
        }
    }, [stateData])

    return(
        <div>
            <Box>
                <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
                    <CngBackdrop loading={loading} />
                </CngGridItem>
                
                <Paper>
                    <Box alignItems='center' pr={15} pl={15}>
                        <GeneralStepperComponent steps={neweVGMSteps} activeStep={0} />
                    </Box>

                    <Box p={5} className='page-content'>
                        <Box>
                            <EvgmInvoiceComponent.FormBody
                                refData = {stateData.isEdit ? stateData.data?.references : ''}
                                isEdit = {stateData.isEdit ? true : false}
                            />
                        </Box>

                        <Box pt={5}>
                            <EvgmCarrierComponent.FormBody
                                carrierData = {stateData.isEdit ? stateData.data?.parties : ''}
                                isEdit = {stateData.isEdit ? true : false}
                                isCopy = {stateData.isCopy ? true : false}
                                showNotification={showNotification}
                            />
                        </Box>

                        <Box pt={5}>
                            <EvgmShipperComponent.FormBody
                                shipperData = {stateData.isEdit ? stateData.data?.parties : ''}
                                isEdit = {stateData.isEdit ? true : false}
                                isCopy = {stateData.isCopy ? true : false}
                                showNotification={showNotification}
                            />
                        </Box>

                        <Box pt={5}>
                            <EvgmConsignorComponent.FormBody
                                consignorData = {stateData.isEdit ? stateData.data?.parties : ''}
                                isEdit = {stateData.isEdit ? true : false}
                                isCopy = {stateData.isCopy ? true : false}
                                showNotification={showNotification}
                            />
                        </Box>

                        <Box pt={5}>
                            <EvgmContainerDetailsComponent.FormBody
                                containerData = {stateData.isEdit ? stateData.data?.evgmContainer : ''}
                                isEdit = {stateData.isEdit ? true : false}
                                isCopy = {stateData.isCopy ? true : false}
                            />
                        </Box>

                        <Box pt={5}>
                            <EvgmContainerDocumentComponent.FormBody
                                containerData = {stateData.isEdit ? stateData.data?.evgmContainer : ''}
                                docData = {stateData.isEdit ? stateData.data?.evgmContainer?.containerDocument : ''}
                                isEdit = {stateData.isEdit ? true : false}
                            />
                        </Box>
                    </Box>
                </Paper>

                <Paper className='sticky-footer'>
                    <Box alignItems='center'>
                        <EvgmButtonComponent
                            onSaveDraft={onSaveDraft}
                            onConfirmReview={onConfirmReview}
                            onDiscard={onDiscard}
                        />
                    </Box>
                </Paper>

                <WarningDialog
                    isDialogOpen={warningDialogOpen}
                    closeDialog={() => setWarningDialogOpen(false)}
                    okDialog={() => setWarningDialogOpen(false)}
                    content={fbTranslatedTextsObject.formErrorWarning}
                    okMsg={uiTranslatedTextsObject.ok}
                />

                <ConfirmDialog
                    isConfirmDialogOpen={confirmDialogOpen}
                    closeDialog={() => setConfirmDialogOpen(false)}
                    confirmDialog={discardEvgm}
                    content={evgmTranslatedTextsObject.dialogDiscardEvgmContent}
                    okMsg={uiTranslatedTextsObject.ok}
                    cancelMsg={uiTranslatedTextsObject.cancel}
                    title={evgmTranslatedTextsObject.dialogDiscardEvgmTitle}
                />
            </Box>
        </div>
    )
}

const CreateEVGMFormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    FormFields: FormFields
})

export default CreateEVGMFormProperties