import React from 'react'
import EssDocsApiUrls from 'src/apiUrls/EssDocsApiUrls.js'

function fetchSIConfig(fetchRecords, onSuccess) {
  console.log('getSIConfigById...')
  fetchRecords.execute(
    EssDocsApiUrls.GET_SI_CONFIG,
    {
      customData: ""
    },
    (data) => {
      onSuccess(data)
    },
    (error) => {
      console.log(error)
    }
  )
}

const EssDocsApi = Object.freeze({
  fetchSIConfig
})

export default EssDocsApi
