import { Grid, Typography } from '@material-ui/core'

import React from 'react'
import Utils from 'src/views/common/utils/Utils'
import EvgmTranslationText from '../shared/EvgmTranslationText'

const EvgmDetailBottom = (props) => {
    const { evgmContainerId, updatedDate } = props
    const translatedTextsObject = EvgmTranslationText()

    return (
        <footer className={`cng-summary-card--footer-box`}>
            <Grid container xs={12} style={{ height: '100%' }}>
                <Grid item xs={12} sm={7}>
                    <div className={`cng-summary-card--footer-box--left`}>
                        <span>
                            <img
                            src={`${process.env.PUBLIC_URL}/static/images/Logo_CALISTA.svg`}
                            ></img>
                        </span>

                        <Typography className={'frb-card-label-large-black'}>
                            {translatedTextsObject.evgmContainerId + ': '}
                            <b>
                                {evgmContainerId}
                            </b>
                        </Typography>
        
                    </div>
                </Grid>
        
                <Grid item xs={12} sm={5}>
                    <div className={`cng-summary-card--footer-box--right`}>
                        <div className={`footer-box--right-col-1 text-right-align`}>
                            <Typography className={'frb-card-label-large-grey'}>
                                {translatedTextsObject.lastModifiedOn + ' '}
                                <b>
                                    {Utils.formatDate(
                                        updatedDate,
                                        Utils.FORMAT_DATE_TIME,
                                        Utils.DASH
                                    )}
                                </b>
                            </Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </footer>
    )
}

export default EvgmDetailBottom