import { Chip, Grid, Typography } from '@material-ui/core'

import EvgmTranslationText from '../shared/EvgmTranslationText'
import LabelValueVerticalSimple from './LabelValueVerticalSimple'
import React from 'react'
import Utils from '../shared/Utils'
import { components } from 'cng-web-lib'
import EvgmActionMenu from './EvgmActionMenu'

const { CngGridItem } = components

const EvgmDetailTop = (props) => {
    const { evgm } = props
    const { evgmStatus, evgmStatusDesc, evgmId } = evgm
    const translatedTextsObject = EvgmTranslationText()
    console.log('eVGM Status: ' + evgmStatus)

    console.log(Utils.getEvgmStatusById(evgmStatus).color)
    console.log("CHECK EVGM ID,", evgmId)
    let temp = Utils.getEvgmStatusById(evgmStatus).color

    return (
        <header className={`cng-summary-card--header-box`}>
            <Grid container xs={12} style={{ height: '100%' }}>
                <Grid item xs={12} sm={6}>
                    <div className={`cng-summary-card--header-box--left`}>
                        <Chip
                            label={
                                <Typography className={`font-mediumBold font-16`}>
                                    {evgmStatusDesc.toUpperCase()}
                                </Typography>
                            }
                            style = {{ backgroundColor: temp }}
                        />
                    </div>
                </Grid>
        
                <Grid item xs={12} sm={6}>
                    <div className={`cng-summary-card--header-box--right`}>
                        <div className={`header-box--right-col-1`}>
                            <LabelValueVerticalSimple
                                label={translatedTextsObject.evgmId + ':'}
                                value={evgmId}
                                defaultValue='N/A'
                                labelStyle={'field-label'}
                                valueStyle={'field-value font-mediumBold'}
                            />
                        </div>
            
                        <div className={`header-box--right-col-2 cta-box`}>
                            {(
                                <div className={`cng-summary-card--more-actions-wrapper`}>
                                    {
                                        <EvgmActionMenu
                                            evgm={evgm}
                                            {...props}
                                            page={Utils.Page.EvgmList}
                                        />
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </header>
    )
}

export default EvgmDetailTop