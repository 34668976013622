import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Grid,
    InputAdornment,
    IconButton
} from '@material-ui/core'
import { v4 as uuid } from 'uuid'
import React, { useEffect, useState } from 'react'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import { components } from 'cng-web-lib'
import EvgmTranslationText from '../shared/EvgmTranslationText'
import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import ListCompanyContactDialog from 'src/views/company-preference/addressbook/components/addressbook/ListCompanyContactDialog'
import ListPartyTemplateDialog from 'src/views/company-preference/addressbook/components/partytemplate/ListPartyTemplateDialog'
import AddContactSIDialog from 'src/views/company-preference/addressbook/components/addressbook/AddContactSIDialog'
import CarrierAutocompleteField from 'src/components/autocomplete/CarrierAutocompleteField'

const {
    form: {
        adapter: {
        useFormAdapter: { useField, useFormikContext }
        },
        field: {
        CngCodeMasterAutocompleteField,
        CngTextField,
        CngSelectField
        }
    },
    CngGridItem,
    table: { useDefaultNotification }
} = components

const initialValues = Object.freeze({
    carrierPartyIdCode: '',
    carrierPartyName: '',
    carrierPartyIdType: '',
    carrierAgencyCode:'',
    carrierContactType: '',
    carrierContactName: '',
    carrierContactAddress:'',
    carrierAddressCityName:'',
    carrierAddressPostalCode:'',
    carrierAddressCountryName:'',
    carrierAddressCountryCode:'',
    carrierPhoneNumber:'',
    carrierEmailAddress:''
})

const FormBody = (props) => {

    const { success: showSuccessNotification} =
    useDefaultNotification(props.showNotification)
    const evgmTranslatedTextsObject = EvgmTranslationText()
    const [additionalInfoExpanded, setAdditionalInfoExpanded] = useState(false)
    const [addressBookDialogOpen, setAddressBookDialogOpen] = useState(false)
    const [partyTemplateDialogOpen, setPartyTemplateDialogOpen] = useState(false)
    const [addContactDialogOpen, setAddContactDialogOpen] = useState(false)
    const [refreshContactList, setRefreshContactList] = useState(false)
    const [contactType, setContactType] = useState('')
    const { setFieldValue } = useFormikContext()
    const [carrierKey, setCarrierKey] = useState()

    function closeAddContactDialog() {
        setAddContactDialogOpen(false)
    }

    const handleAdditionalInfoExpanded = () => {
        setAdditionalInfoExpanded(!additionalInfoExpanded)
    }

    const doRefresh = () => {
        setRefreshContactList(!refreshContactList)
    }

    const getAddressbook = (contactType) => {
        setAddressBookDialogOpen(true)
        setContactType(contactType)
    }

    function closeDialog() {
        setAddressBookDialogOpen(false)
        setPartyTemplateDialogOpen(false)
    }

    function handleSelectContact(data) {
        console.log('handleSelectContact', data)
        
        setFieldValue('carrierContactName', data.carrName)
        setFieldValue('carrierContactAddress', data.carrAddress)
        setFieldValue('carrierEmailAddress', data.carrEmail)
        setFieldValue('carrierAddressCityName', data.carrCityName)
        setFieldValue('carrierAddressPostalCode', data.carrPostalCode)
        setFieldValue('carrierAddressCountryCode', data.carrCountryCode)
        setFieldValue('carrierAddressCountryName', data.carrCountryName)
        setFieldValue('carrierPhoneNumber', data.carrContactNo)

        showSuccessNotification('Carrier details populated')

        closeDialog()
    }

    const addNewContact = (contactType) => {
        console.log('addNewContact', contactType)
        setAddContactDialogOpen(true)
        setContactType(contactType)
    }

    function handleSelectTemplate(data){
        console.log('handleSelectTemplate', data)

        setFieldValue(
            'carrierContactName',
            data.selectedCarrierName != null ? data.selectedCarrierName : ''
        )
        setFieldValue(
            'carrierContactAddress',
            data.selectedCarrierAddress != null ? data.selectedCarrierAddress : ''
        )
        setFieldValue(
            'carrierEmailAddress',
            data.selectedCarrierEmail != null ? data.selectedCarrierEmail : ''
        )
        setFieldValue(
            'carrierPhoneNumber',
            data.selectedCarrierContactNo != null ? data.selectedCarrierContactNo : ''
        )

        setFieldValue(
            'carrierAddressCityName',
             data.selectedCarrierCityName != null ? data.selectedCarrierCityName : ''
        )

        setFieldValue(
            'carrierAddressPostalCode',
             data.selectedCarrierPostalCode != null ? data.selectedCarrierPostalCode : ''
        )

        setFieldValue(
             'carrierAddressCountryCode',
              data.selectedCarrierCountryCode != null ? data.selectedCarrierCountryCode : ''
        )

        setFieldValue(
             'carrierAddressCountryName',
              data.selectedCarrierCountryName != null ? data.selectedCarrierCountryName : ''
        )

        showSuccessNotification('Party Template details populated.')
        closeDialog()
    }

    useEffect(() => {

        if(props.isEdit){
            setCarrierKey(uuid())

            const carrierData = props.carrierData

            // Initialize variables
            let carrierPartyIdCode = null
            //let carrierPartyName = null
            let carrierPartyIdType = null
            let carrierAgencyCode = null
            let carrierContactType = null
            let carrierContactName = null
            let carrierContactAddress = null
            let carrierPhoneNumber = null
            let carrierEmailAddress = null
            let carrierAddressCityName = null
            let carrierAddressPostalCode = null
            let carrierAddressCountryName = null
            let carrierAddressCountryCode = null

            // Find the object with role "CA"
            const caParty = carrierData.find((party) => party.role == "CA");

            if(props.isCopy){
                if (caParty) {
                    carrierPartyIdCode = parseInt(caParty.partyId) || ''
                    //carrierPartyName = caParty.partyName || ''
                    carrierPartyIdType = caParty.partyIdType || caParty.partyIdCode ||  ''
                    carrierAgencyCode = caParty.agencyCode || ''
                    carrierContactType = caParty.partyContacts?.[0]?.contactType || ''
                    carrierContactName = caParty.partyContacts?.[0]?.contactName || ''
                    carrierContactAddress = caParty.address?.streetAddress || caParty.streetAddress || ''
                    carrierAddressPostalCode = caParty.address?.postalCode || caParty.postalCode || ''
                    carrierAddressCityName = caParty.address?.cityName || caParty.cityName || ''
                    carrierAddressCountryName = caParty.address?.countryName || caParty.countryName || ''
                    carrierAddressCountryCode = caParty.address?.countryCode || caParty.countryCode || ''
    
                    //extract carrierPhoneNumber
                    const teCommunication = caParty.partyContacts?.[0]?.contactCommunication.find(
                        (communication) => communication.commType == "TE"
                    )
                    
                    if (teCommunication) {
                        carrierPhoneNumber = teCommunication.commNo
                    }
    
                    //extract carrierEmailAddress
                    const bnCommunication = caParty.partyContacts?.[0]?.contactCommunication.find(
                        (communication) => communication.commType == "EM"
                    )
                    
                    if (teCommunication) {
                        carrierEmailAddress = bnCommunication.commNo
                    }
                }
            }else{
                if (caParty) {
                    carrierPartyIdCode = caParty.partyId || ''
                    //carrierPartyName = caParty.partyName || ''
                    carrierPartyIdType = caParty.partyIdType || caParty.partyIdCode ||  ''
                    carrierAgencyCode = caParty.agencyCode || ''
                    carrierContactType = caParty.partyContacts?.[0]?.contactType || ''
                    carrierContactName = caParty.partyContacts?.[0]?.contactName || ''
                    carrierContactAddress = caParty.address?.streetAddress || caParty.streetAddress || ''
                    carrierAddressPostalCode = caParty.address?.postalCode || caParty.postalCode || ''
                    carrierAddressCityName = caParty.address?.cityName || caParty.cityName || ''
                    carrierAddressCountryName = caParty.address?.countryName || caParty.countryName || ''
                    carrierAddressCountryCode = caParty.address?.countryCode || caParty.countryCode || ''
    
                    //extract carrierPhoneNumber
                    const teCommunication = caParty.partyContacts?.[0]?.contactCommunication.find(
                        (communication) => communication.commType == "TE"
                    )
                    
                    if (teCommunication) {
                        carrierPhoneNumber = teCommunication.commNo
                    }
    
                    //extract carrierEmailAddress
                    const bnCommunication = caParty.partyContacts?.[0]?.contactCommunication.find(
                        (communication) => communication.commType == "EM"
                    )
                    
                    if (teCommunication) {
                        carrierEmailAddress = bnCommunication.commNo
                    }
                }
            }
            

            // Now you have the values assigned to the desired variables
            setFieldValue('carrierPartyIdCode', carrierPartyIdCode)
            //setCarrierPartyName(carrierPartyName)
            setFieldValue('carrierPartyIdType', carrierPartyIdType)
            setFieldValue('carrierAgencyCode', carrierAgencyCode)
            setFieldValue('carrierContactType', carrierContactType)
            setFieldValue('carrierContactName', carrierContactName)
            setFieldValue('carrierContactAddress', carrierContactAddress)
            setFieldValue('carrierAddressPostalCode', carrierAddressPostalCode)
            setFieldValue('carrierAddressCityName', carrierAddressCityName)
            setFieldValue('carrierAddressCountryName', carrierAddressCountryName)
            setFieldValue('carrierAddressCountryCode', carrierAddressCountryCode)
            setFieldValue('carrierPhoneNumber', carrierPhoneNumber)
            setFieldValue('carrierEmailAddress', carrierEmailAddress)

        }

    }, [])


    
    return(
        <Card>
            <Accordion expanded={additionalInfoExpanded}>
                <AccordionSummary>
                    <Grid container>
                        <CngGridItem xs={6} sm={6}>
                            <AccordionHeaderComponent 
                                title = {evgmTranslatedTextsObject.carrierInfoTitle}
                            />
                        </CngGridItem>

                        <Grid container spacing={2} alignItems='center'>

                            <CngGridItem xs={4} sm={4}>
                                <CarrierAutocompleteField
                                    name='carrierPartyIdCode'
                                    key={carrierKey}
                                    label={evgmTranslatedTextsObject.carrierPartyId}
                                    required
                                />
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                <CngCodeMasterAutocompleteField
                                    name='carrierPartyIdType'
                                    label={evgmTranslatedTextsObject.partyIdType}
                                    key={carrierKey}
                                    codeType='EVGM_PARTY_ID_TYPE'
                                    required
                                />
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                <CngCodeMasterAutocompleteField
                                    name='carrierAgencyCode'
                                    label={evgmTranslatedTextsObject.agencyCode}
                                    key={carrierKey}
                                    codeType='EVGM_AGENCY_CODE'
                                    required
                                />
                            </CngGridItem>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <CngGridItem xs={4} sm={4}>
                            <CngCodeMasterAutocompleteField
                                name='carrierContactType'
                                label={evgmTranslatedTextsObject.carrierContactType}
                                key={carrierKey}
                                codeType='EVGM_CONTACT_TYPE'
                                //required
                            />
                        </CngGridItem>
                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type= 'text'
                                name = 'carrierContactName'
                                label = {evgmTranslatedTextsObject.contactName}
                                inputProps = {{ maxLength: 512 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                onClick={(e) => getAddressbook('CARRIER')}
                                            >
                                            {' '}
                                                <LibraryBooksIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </CngGridItem>
                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type= 'text'
                                name = 'carrierContactAddress'
                                label = {evgmTranslatedTextsObject.contactAddress}
                                inputProps = {{ maxLength: 512 }}
                            />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}/>

                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type= 'text'
                                name = 'carrierPhoneNumber'
                                label = {evgmTranslatedTextsObject.phoneNumber}
                                inputProps = {{ maxLength: 256 }}
                            />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}/>
                        <CngGridItem xs={4} sm={4}/>

                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type= 'text'
                                name = 'carrierEmailAddress'
                                label = {evgmTranslatedTextsObject.emailAddress}
                                inputProps = {{ maxLength: 512 }}
                            />
                        </CngGridItem>
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <AccordionFooterControlComponent
                handleAccordionExpand={handleAdditionalInfoExpanded}
                expanded={additionalInfoExpanded}
                footerText={evgmTranslatedTextsObject.carrierAdditional}
            />

            <ListCompanyContactDialog
                isDialogOpen={addressBookDialogOpen}
                closeDialog={closeDialog}
                contactType={contactType}
                handleDialogSubmit={handleSelectContact}
                handleAddContact={addNewContact}
                showNotification={props.showNotification}
                refresh={refreshContactList}
            />

            <ListPartyTemplateDialog
                styles={{ width: '80%', height: '90%' }}
                isDialogOpen={partyTemplateDialogOpen}
                closeDialog={closeDialog}
                handleDialogSubmit={handleSelectTemplate}
                showNotification={props.showNotification}
            />

            <AddContactSIDialog
                isDialogOpen={addContactDialogOpen}
                closeDialog={closeAddContactDialog}
                contactType={contactType}
                showNotification={props.showNotification}
                doRefresh={doRefresh}
            />
        </Card>
    )
}

const EvgmCarrierComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default EvgmCarrierComponent