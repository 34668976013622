import {
    Box,
    Card,
    Grid,
} from '@material-ui/core'
import React from 'react'
import { components, constants } from 'cng-web-lib'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EvgmTranslationText from '../shared/EvgmTranslationText'
import FooterAddComponent from '../../common/ui/FooterAddComponent'

const {
    form: {
        field: {
            CngTextField,
            CngSelectField,
            CngDateField,
            CngCodeMasterAutocompleteField,
        },
    },
    CngGridItem,
} = components

const {
    component: {
        textField: {
            customType: { PLAIN_NUMBER }
        }
    }
} = constants

const EvgmContainerRefComponent = (props) => {

    const evgmTranslatedTextsObject = EvgmTranslationText()

    return(
        <Card>
            <Box px={2} my={1.5}>
                <Grid container>
                    <AccordionHeaderComponent
                        title={evgmTranslatedTextsObject.ctnRefNoTitle}
                    />
                </Grid>
                <Grid container spacing={2}>
                {
                    props.list.map((m, idx) => (
                        
                        <CngGridItem xs={12} sm={12} key={m.id}>
                            <Card className='inner-card-group'>
                                    <Grid container xs={12} sm={12} spacing={2}>
                                        <CngGridItem xs={4} sm={4}>
                                            <CngCodeMasterAutocompleteField
                                                name={`evgmContainer.references[${idx}].type`}
                                                label={evgmTranslatedTextsObject.ctnRefNoType}
                                                codeType='EVGM_CONTAINER_REF_TYPE'
                                                // onChange={(val, evt) =>
                                                //     handleDropdownChange(val, evt, i, 'refType')
                                                // }
                                                isRequired
                                            />
                                        </CngGridItem>

                                        <CngGridItem xs={4} sm={4}>
                                            <CngTextField
                                                type= 'text'
                                                name={`evgmContainer.references[${idx}].code`}
                                                label={evgmTranslatedTextsObject.ctnRefNoNumber}
                                                value={m.code}
                                                inputProps = {{ maxLength: 70 }}
                                                onChange={e => props.handleInputChange(e, idx)}
                                                required
                                            />
                                        </CngGridItem>

                                        <CngGridItem xs={4} sm={4}>
                                            <IconButton aria-label='copy'>
                                                <FileCopyIcon
                                                    onClick={props.copyRowClick(idx)}
                                                />
                                            </IconButton>
                                            {props.list.length > 1 && (
                                            <IconButton aria-label='remove'>
                                                <DeleteIcon
                                                    onClick={props.removeRowClick(idx)}
                                                />
                                            </IconButton>
                                            )}
                                        </CngGridItem>
                                    </Grid>
                                </Card>
                            </CngGridItem>
                    ))  
                }
                </Grid>
            </Box>
            <FooterAddComponent
                handleAddChild={props.addRowClick}
                footerText={evgmTranslatedTextsObject.ctnRefNoAddRow}
            />
        </Card>
    )
}

export default EvgmContainerRefComponent