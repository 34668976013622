import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Grid,
    InputAdornment,
    IconButton
} from '@material-ui/core'
import { v4 as uuid } from 'uuid'
import React, { useEffect, useState } from 'react'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import { components } from 'cng-web-lib'
import EvgmTranslationText from '../shared/EvgmTranslationText'
import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import ListCompanyContactDialog from 'src/views/company-preference/addressbook/components/addressbook/ListCompanyContactDialog'
import ListPartyTemplateDialog from 'src/views/company-preference/addressbook/components/partytemplate/ListPartyTemplateDialog'
import AddContactSIDialog from 'src/views/company-preference/addressbook/components/addressbook/AddContactSIDialog'


const {
    form: {
        adapter: {
        useFormAdapter: { useField, useFormikContext }
        },
        field: {
        CngCodeMasterAutocompleteField,
        CngTextField,
        CngSelectField
        }
    },
    CngGridItem,
    table: { useDefaultNotification }
} = components

const initialValues = Object.freeze({
    consignorPartyIdCode: '',
    consignorPartyName: '',
    consignorPartyIdType: '',
    consignorAgencyCode:'',
    consignorContactType: '',
    consignorContactName: '',
    consignorContactAddress:'',
    consignorAddressCityName:'',
    consignorAddressPostalCode:'',
    consignorAddressCountryName:'',
    consignorAddressCountryCode:'',
    consignorPhoneNumber:'',
    consignorEmailAddress:''
})

const FormBody = (props) => {

    const { success: showSuccessNotification} =
    useDefaultNotification(props.showNotification)
    const evgmTranslatedTextsObject = EvgmTranslationText()
    const [additionalInfoExpanded, setAdditionalInfoExpanded] = useState(false)
    const [addressBookDialogOpen, setAddressBookDialogOpen] = useState(false)
    const [partyTemplateDialogOpen, setPartyTemplateDialogOpen] = useState(false)
    const [addContactDialogOpen, setAddContactDialogOpen] = useState(false)
    const [refreshContactList, setRefreshContactList] = useState(false)
    const [contactType, setContactType] = useState('')
    const { setFieldValue } = useFormikContext()
    const [carrierKey, setCarrierKey] = useState()

    function closeAddContactDialog() {
        setAddContactDialogOpen(false)
    }

    const handleAdditionalInfoExpanded = () => {
        setAdditionalInfoExpanded(!additionalInfoExpanded)
    }

    const doRefresh = () => {
        setRefreshContactList(!refreshContactList)
    }

    const getAddressbook = (contactType) => {
        setAddressBookDialogOpen(true)
        setContactType(contactType)
    }

    function closeDialog() {
        setAddressBookDialogOpen(false)
        setPartyTemplateDialogOpen(false)
    }

    function handleSelectContact(data) {
        console.log('handleSelectContact', data)
        
        setFieldValue('consignorContactName', data.conName)
        setFieldValue('consignorContactAddress', data.conAddress)
        setFieldValue('consignorEmailAddress', data.conEmail)
        setFieldValue('consignorAddressCityName', data.conCityName)
        setFieldValue('consignorAddressPostalCode', data.conPostalCode)
        setFieldValue('consignorAddressCountryCode', data.conCountryCode)
        setFieldValue('consignorAddressCountryName', data.conCountryName)
        setFieldValue('consignorPhoneNumber', data.conContactNo)

        showSuccessNotification('Consignor details populated')

        closeDialog()
    }

    const addNewContact = (contactType) => {
        console.log('addNewContact', contactType)
        setAddContactDialogOpen(true)
        setContactType(contactType)
    }

    function handleSelectTemplate(data){
        console.log('handleSelectTemplate', data)

        setFieldValue(
            'consignorContactName',
            data.selectedConsigneeName != null ? data.selectedConsigneeName : ''
        )
        setFieldValue(
            'consignorContactAddress',
            data.selectedConsigneeAddress != null ? data.selectedConsigneeAddress : ''
        )
        setFieldValue(
            'consignorEmailAddress',
            data.selectedConsigneeEmail != null ? data.selectedConsigneeEmail : ''
        )
        setFieldValue(
            'consignorPhoneNumber',
            data.selectedConsigneeContactNo != null ? data.selectedConsigneeContactNo : ''
        )

        setFieldValue(
            'consignorAddressCityName',
            data.selectedConsignorCityName != null ? data.selectedConsignorCityName : ''
        )

        setFieldValue(
             'consignorAddressPostalCode',
             data.selectedConsignorPostalCode != null ? data.selectedConsignorPostalCode : ''
        )

        setFieldValue(
            'consignorAddressCountryCode',
            data.selectedConsignorCountryCode != null ? data.selectedConsignorCountryCode : ''
        )

        setFieldValue(
            'consignorAddressCountryName',
            data.selectedConsignorCountryName != null ? data.selectedConsignorCountryName : ''
        )

        showSuccessNotification('Party Template details populated.')
        closeDialog()
    }

    useEffect(() => {

        if(props.isEdit){
            setCarrierKey(uuid())

            const consignorData = props.consignorData

            // Initialize variables
            //let consignorPartyIdCode = null
            let consignorPartyName = null
            let consignorPartyIdType = null
            let consignorAgencyCode = null
            let consignorContactType = null
            let consignorContactName = null
            let consignorContactAddress = null
            let consignorPhoneNumber = null
            let consignorEmailAddress = null
            let consignorAddressCityName = null
            let consignorAddressPostalCode = null
            let consignorAddressCountryName = null
            let consignorAddressCountryCode = null

            // Find the object with role "CA"
            const caParty = consignorData.find((party) => party.role == "CZ");
            if(props.isCopy){
                if (caParty) {
                    //consignorPartyIdCode = caParty.partyId || ''
                    consignorPartyName = caParty.name || ''
                    consignorPartyIdType = caParty.partyIdType || caParty.partyIdCode || ''
                    consignorAgencyCode = caParty.agencyCode || ''
                    consignorContactType = caParty.partyContacts?.[0]?.contactType || ''
                    consignorContactName = caParty.partyContacts?.[0]?.contactName || ''
                    consignorContactAddress = caParty.address?.streetAddress || caParty.streetAddress || ''
                    consignorAddressPostalCode = caParty.address?.postalCode || caParty.postalCode || ''
                    consignorAddressCityName = caParty.address?.cityName || caParty.cityName || ''
                    consignorAddressCountryName = caParty.address?.countryName || caParty.countryName || ''
                    consignorAddressCountryCode = caParty.address?.countryCode || caParty.countryCode || ''
    
                    //extract carrierPhoneNumber
                    const teCommunication = caParty.partyContacts?.[0]?.contactCommunication.find(
                        (communication) => communication.commType == "TE"
                    )
                    
                    if (teCommunication) {
                        consignorPhoneNumber = teCommunication.commNo
                    }
    
                    //extract carrierEmailAddress
                    const bnCommunication = caParty.partyContacts?.[0]?.contactCommunication.find(
                        (communication) => communication.commType == "EM"
                    )
                    
                    if (teCommunication) {
                        consignorEmailAddress = bnCommunication.commNo
                    }
                }
            }else{
                if (caParty) {
                    //consignorPartyIdCode = caParty.partyId || ''
                    consignorPartyName = caParty.partyName || ''
                    consignorPartyIdType = caParty.partyIdType || caParty.partyIdCode || ''
                    consignorAgencyCode = caParty.agencyCode || ''
                    consignorContactType = caParty.partyContacts?.[0]?.contactType || ''
                    consignorContactName = caParty.partyContacts?.[0]?.contactName || ''
                    consignorContactAddress = caParty.address?.streetAddress || caParty.streetAddress || ''
                    consignorAddressPostalCode = caParty.address?.postalCode || caParty.postalCode || ''
                    consignorAddressCityName = caParty.address?.cityName || caParty.cityName || ''
                    consignorAddressCountryName = caParty.address?.countryName || caParty.countryName || ''
                    consignorAddressCountryCode = caParty.address?.countryCode || caParty.countryCode || ''
    
                    //extract carrierPhoneNumber
                    const teCommunication = caParty.partyContacts?.[0]?.contactCommunication.find(
                        (communication) => communication.commType == "TE"
                    )
                    
                    if (teCommunication) {
                        consignorPhoneNumber = teCommunication.commNo
                    }
    
                    //extract carrierEmailAddress
                    const bnCommunication = caParty.partyContacts?.[0]?.contactCommunication.find(
                        (communication) => communication.commType == "EM"
                    )
                    
                    if (teCommunication) {
                        consignorEmailAddress = bnCommunication.commNo
                    }
                }
            }
            

            // Now you have the values assigned to the desired variables
            //setFieldValue('consignorPartyIdCode', consignorPartyIdCode)
            setFieldValue('consignorPartyName', consignorPartyName)
            setFieldValue('consignorPartyIdType', consignorPartyIdType)
            setFieldValue('consignorAgencyCode', consignorAgencyCode)
            setFieldValue('consignorContactType', consignorContactType)
            setFieldValue('consignorContactName', consignorContactName)
            setFieldValue('consignorContactAddress', consignorContactAddress)
            setFieldValue('consignorAddressPostalCode', consignorAddressPostalCode)
            setFieldValue('consignorAddressCityName', consignorAddressCityName)
            setFieldValue('consignorAddressCountryName', consignorAddressCountryName)
            setFieldValue('consignorAddressCountryCode', consignorAddressCountryCode)
            setFieldValue('consignorPhoneNumber', consignorPhoneNumber)
            setFieldValue('consignorEmailAddress', consignorEmailAddress)

        }

    }, [])

    
    return(
        <Card>
            <Accordion expanded={additionalInfoExpanded}>
                <AccordionSummary>
                    <Grid container>
                        <CngGridItem xs={6} sm={6}>
                            <AccordionHeaderComponent 
                                title = {evgmTranslatedTextsObject.consignorInfoTitle}
                            />
                        </CngGridItem>

                        <Grid container spacing={2} alignItems='center'>

                            <CngGridItem xs={4} sm={4}>
                                <CngTextField
                                    type= 'text'
                                    name = 'consignorPartyName'
                                    label = {evgmTranslatedTextsObject.consignorPartyId}
                                    inputProps = {{ maxLength: 512 }}
                                    required
                                >
                                </CngTextField>
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                <CngCodeMasterAutocompleteField
                                    name='consignorPartyIdType'
                                    label={evgmTranslatedTextsObject.partyIdType}
                                    codeType='EVGM_PARTY_ID_TYPE'
                                    key={carrierKey}
                                    required
                                />
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                <CngCodeMasterAutocompleteField
                                    name='consignorAgencyCode'
                                    label={evgmTranslatedTextsObject.agencyCode}
                                    codeType='EVGM_AGENCY_CODE'
                                    key={carrierKey}
                                    required
                                />
                            </CngGridItem>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <CngGridItem xs={4} sm={4}>
                            <CngCodeMasterAutocompleteField
                                name='consignorContactType'
                                label={evgmTranslatedTextsObject.consignorContactType}
                                codeType='EVGM_CONTACT_TYPE'
                                key={carrierKey}
                                //required
                            />
                        </CngGridItem>
                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type= 'text'
                                name = 'consignorContactName'
                                label = {evgmTranslatedTextsObject.contactName}
                                inputProps = {{ maxLength: 512 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                onClick={(e) => getAddressbook('CONSIGNEE')}
                                            >
                                            {' '}
                                                <LibraryBooksIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </CngGridItem>
                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type= 'text'
                                name = 'consignorContactAddress'
                                label = {evgmTranslatedTextsObject.contactAddress}
                                inputProps = {{ maxLength: 512 }}
                            />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}/>

                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type= 'text'
                                name = 'consignorPhoneNumber'
                                label = {evgmTranslatedTextsObject.phoneNumber}
                                inputProps = {{ maxLength: 256 }}
                            />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}/>
                        <CngGridItem xs={4} sm={4}/>

                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type= 'text'
                                name = 'consignorEmailAddress'
                                label = {evgmTranslatedTextsObject.emailAddress}
                                inputProps = {{ maxLength: 512 }}
                            />
                        </CngGridItem>
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <AccordionFooterControlComponent
                handleAccordionExpand={handleAdditionalInfoExpanded}
                expanded={additionalInfoExpanded}
                footerText={evgmTranslatedTextsObject.consignorAdditional}
            />

            <ListCompanyContactDialog
                isDialogOpen={addressBookDialogOpen}
                closeDialog={closeDialog}
                contactType={contactType}
                handleDialogSubmit={handleSelectContact}
                handleAddContact={addNewContact}
                showNotification={props.showNotification}
                refresh={refreshContactList}
            />

            <ListPartyTemplateDialog
                styles={{ width: '80%', height: '90%' }}
                isDialogOpen={partyTemplateDialogOpen}
                closeDialog={closeDialog}
                handleDialogSubmit={handleSelectTemplate}
                showNotification={props.showNotification}
            />

            <AddContactSIDialog
                isDialogOpen={addContactDialogOpen}
                closeDialog={closeAddContactDialog}
                contactType={contactType}
                showNotification={props.showNotification}
                doRefresh={doRefresh}
            />
        </Card>
    )
}

const EvgmConsignorComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default EvgmConsignorComponent